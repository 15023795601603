import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DataStorageService } from '@shared/services/data-storage.service';
import { WorkersService } from '../workers.service';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
  myForm: UntypedFormGroup;
  myFormFex: UntypedFormGroup;
  public roles: any[];
  fileToUpload: File | null = null;
  fileName: string;
  isUpload:boolean;
  typeError:boolean;
  type: any;
  message = '';
  columns:any[]=['name','surname','email'];
  constructor(protected dialogRef: MatDialogRef<DialogComponent>,
              @Inject(MAT_DIALOG_DATA) data,
              private dataStorageService: DataStorageService,
              private workerService: WorkersService,
              private formBuilder: UntypedFormBuilder) {
    this.isUpload = data.upload;
    this.message = data.message;
    this.type = data.type;
    if(!this.isUpload){
      this.myForm = formBuilder.group({
        rol: ['', Validators.required],
      });
      this.myFormFex = formBuilder.group({
        numEntradas: ['', Validators.required],
      });
    }
  }

  ngOnInit(): void {
    if(!this.isUpload){
      this.getRol();
    }
  }

  handleFileInput(files: any) {
    this.fileToUpload = files.item(0);
    let type = this.fileToUpload.type;
    this.fileName = this.fileToUpload.name;

    if (!type.includes('spreadsheetml')){
      this.typeError = true
    } else{
      this.typeError = false;
    }
  }

  getRol() {
    console.log(localStorage.getItem('eventId'));
    this.roles = this.workerService.getRoles().slice();
    const rolesCopy = [];
    for (const role of this.roles) {
      if (role.name !== 'Cliente' && JSON.parse(localStorage.getItem('eventId')) !== 'a5afba2d-a8a2-4ec8-9c95-4ba478531f08' && JSON.parse(localStorage.getItem('eventId')) !== '0060891e-74b8-4273-9140-d7f9ec4f4e71') {
        rolesCopy.push(role);
      } else if ((JSON.parse(localStorage.getItem('eventId')) === 'a5afba2d-a8a2-4ec8-9c95-4ba478531f08' || JSON.parse(localStorage.getItem('eventId')) === '0060891e-74b8-4273-9140-d7f9ec4f4e71') && !role.name.includes('Camarero') && !role.name.includes('Monitor') && !role.name.includes('Cliente') && !role.name.includes('Taquillero') && !role.name.includes('Boat') && !role.name.includes('Leadscanner') && !role.name.includes('Dashboard') && !role.name.includes('Accesos')) {
        rolesCopy.push(role);
      }
    }
    this.roles = rolesCopy;
  }

  postSubmit() {
    if(!this.isUpload){
      if(this.myForm.value !== null && this.myForm.value !== undefined && this.myForm.value !== ''){
        this.dialogRef.close(this.myForm.value);
      }else{
        this.dialogRef.close();
      }
    }else{
      this.dialogRef.close(this.fileToUpload);
    }
  }

  postSubmitFex() {
    if(!this.isUpload){
      if(this.myFormFex.value !== null && this.myFormFex.value !== undefined && this.myFormFex.value !== ''){
        this.dialogRef.close(this.myFormFex.value);
      }else{
        this.dialogRef.close();
      }
    }else{
      this.dialogRef.close(this.fileToUpload);
    }
  }
}
