<div class="contenedor_global">
  <div class="contenedor_cabecera_completa">
      <div class="contenedor_cabecera">
          <div class="cabecera">
              <p>{{"notifications.title" | translate}}</p>
          </div>
      </div>
  </div>

  <div class="contenedor_add_event">
      <!-- <div class="evento_anadido" *ngFor="let elemento_evento of data" (click)="abrirFormularioEditPopup(elemento_evento)" style="cursor:pointer;"> -->
      <div *ngIf="getScreenWidth >= 500" class="notification_type"  style="cursor:pointer; background-color: transparent !important; height: 70px">
        <div class="foto_title">
          <mat-icon>filter_list</mat-icon>
          <div style="display: flex !important; justify-content: left; align-items: center; padding: 0px 0px 0px 0px">

          <p>
              {{langJson.notifications.filter_from}}:
          </p>

          <input matInput type="datetime-local" [(ngModel)]="startingDate"
          style="border-left: none; border-right: none; border-top: none; border-bottom-width: 1px !important; font-size: 14px; height: 20px; width: 160px; background-color: transparent;">

          <p>
            {{langJson.notifications.filter_to}}:
          </p>

          <div style="width: 5px !important"></div>
            <input matInput type="datetime-local" [(ngModel)]="endDate"
            style="border-left: none; border-right: none; border-top: none; border-bottom-width: 1px !important; font-size: 14px; height: 20px; width: 160px; background-color: transparent;">
            <mat-icon style="padding-left: 10px" *ngIf="startingDate <= endDate" (click)="cargar_subjects(startingDate, endDate)">check</mat-icon>
          </div>

          <div *ngIf="eventId === 'c65bf93b-c7cf-4801-b574-0a6dc8420528'" class="icon filter" style="position: absolute; right: 25px" (click)="usersToExcel()">
            <img style="height: 30px;" src="../../assets/shared-icons/csv_filled.svg">
          </div>
        </div>
      </div>

      <div *ngIf="getScreenWidth < 500" class="notification_type"  style="cursor:pointer; background-color: transparent !important">
        <div class="foto_title">
          <mat-icon>filter_list</mat-icon>
          <div style="display: flex !important; justify-content: left; align-items: center; padding: 0px 0px 0px 0px">
          <p>
              {{langJson.notifications.filter_from}}:
          </p>
          <input matInput type="datetime-local" [(ngModel)]="startingDate"
          style="border-left: none; border-right: none; border-top: none; border-bottom-width: 1px !important; font-size: 14px; height: 20px; width: 160px; background-color: transparent;">
          </div>
        </div>
      </div>
      <div *ngIf="getScreenWidth < 500" class="notification_type"  style="cursor:pointer; background-color: transparent !important">
        <div class="foto_title">
          <mat-icon></mat-icon>
          <div style="display: flex !important; justify-content: left; align-items: center; padding: 0px 0px 0px 0px">
          <p>
            {{langJson.notifications.filter_to}}:
          </p>
          <div style="width: 5px !important"></div>
          <input matInput type="datetime-local" [(ngModel)]="endDate"
          style="border-left: none; border-right: none; border-top: none; border-bottom-width: 1px !important; font-size: 14px; height: 20px; width: 160px; background-color: transparent;">
          <mat-icon style="padding-left: 10px" *ngIf="startingDate <= endDate" (click)="cargar_subjects(startingDate, endDate)">check</mat-icon>
          <div *ngIf="eventId === 'c65bf93b-c7cf-4801-b574-0a6dc8420528'" class="icon filter" style="position: absolute; right: 25px" (click)="usersToExcel()">
            <img style="height: 30px;" src="../../assets/shared-icons/csv_filled.svg">
          </div>
          </div>
        </div>
      </div>

      <ng-container *ngIf="eventId === 'a5afba2d-a8a2-4ec8-9c95-4ba478531f08' || eventId === '0060891e-74b8-4273-9140-d7f9ec4f4e71'">
        <div style="display: flex; flex-direction: row; align-items: center; justify-content: center;">
          <button mat-button class="primary" (click)="goPopUpSend()" style="background-color: #0f223e !important; color: white; margin-bottom: 20px;">Invitaciones</button>
        </div>
      </ng-container>

      <div class="notification_type" *ngFor="let elemento_notificacion of respuesta_notifications" (click)="openNotificationsDialogPopup(elemento_notificacion)" style="cursor:pointer;">
        <div class="foto_title">
          <mat-icon >arrow_forward</mat-icon>
          <p>
              {{elemento_notificacion}}
          </p>
        </div>
        <div class="total_notifications">
            <p>{{elemento_notificacion.number_notifications}}</p>
        </div>
      </div>
  </div>
</div>


<app-footer [activeTab]="'notifications'"></app-footer>
