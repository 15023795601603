import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from '@shared/angular-material';

import { BracelitDirectivesModule } from '@bracelit-components/common/bracelit-directives/bracelit-directives.module';

import { BracelitFormItemComponent } from './bracelit-form-item.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    BracelitFormItemComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    BracelitDirectivesModule
  ],
  exports: [
    BracelitFormItemComponent
  ]
})
export class BracelitFormItemModule {
}
