import { BracelitModel } from '@shared/abstract/bracelit-model';
import { Ability } from './ability';

/**
 * Role class.
 */
export class Role extends BracelitModel {
  /** The name of the role. */
  name: string;
  /** The slug(unique) of the role. */
  slug: string;
  /** The description of the role. */
  description: string;

  /** The array of abilities attached to the role. */
  abilities: Ability[];

  /**
   * Constructor.
   * @param data
   */
  constructor(data: object) {
    super(data);
    this.abilities = [];
    if (data.hasOwnProperty('abilities') && data['abilities']) {
      for (const ability of data['abilities']) {
        this.abilities.push(new Ability(ability));
      }
    }
  }
}
