<div *ngIf="!isUpload && type !== 'booking'">
  <div>
    <form [formGroup]="myForm" class="login-form-container light-22px-center-white" style="max-width: 500px !important;" fxLayout="row wrap"
  fxLayoutAlign="center center" (ngSubmit)="postSubmit()">

      <label [for]="'product-category'" class="primary-color" style="margin-right: 0px; font-size: 16px; width: 100%; text-align: left">
        {{ 'dialog.role' | translate }}</label>

      <mat-form-field appearance="outline" style="width: 100%; font-size: 16px !important" >
        <mat-select [formControlName]="'rol'" placeholder="{{ 'dialog.role' | translate }}" floatlLabel="never">
          <mat-option *ngFor="let rol of roles" [value]="rol">
            {{rol.name}}
          </mat-option>
        </mat-select>
        <mat-error >{{ 'events.formRequiredField' | translate }}</mat-error>
      </mat-form-field>
    </form>
  </div>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10"  class="submit">
    <button fxLayoutAlign="center center" class="secondary"
            [class.disabled]="!myForm.valid"
            mat-button
            (click)="postSubmit()">{{ 'dialog.filter' | translate }}</button>
  </div>
</div>

<div *ngIf="!isUpload && type === 'booking'">
  <div>
    <form [formGroup]="myFormFex" class="login-form-container light-22px-center-white" style="max-width: 500px !important;" fxLayout="row wrap"
  fxLayoutAlign="center center" (ngSubmit)="postSubmitFex()">

      <label [for]="'product-category'" class="primary-color" style="margin-right: 0px; font-size: 16px; width: 100%; text-align: left">
        {{ message }}</label>

      <mat-form-field appearance="outline" style="width: 100%; font-size: 16px !important" >
        <input [formControlName]="'numEntradas'" placeholder="Número" type="number" min="0" />
        <mat-error >{{ 'events.formRequiredField' | translate }}</mat-error>
      </mat-form-field>
    </form>
  </div>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10"  class="submit">
    <button fxLayoutAlign="center center" class="secondary"
            [class.disabled]="!myFormFex.valid"
            mat-button
            (click)="postSubmitFex()">Descargar</button>
  </div>
</div>

<div *ngIf="isUpload && type === 'sendInvitationMail'" class="image-container filled" fxLayout="column" fxLayoutAlign="center center">
  <p>{{ 'dialog.excel' | translate }}</p>
  <ol>
    <li>Email destinatario</li>
  </ol>
  <div class="custom-input-file tertiary-background">
    <input #file class="input-file" type="file" value="" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
           (change)="handleFileInput($event.target.files)" >{{ 'selectFile' | translate }}
  </div>
  <div>{{fileName}}</div>
  <mat-error *ngIf="typeError">{{ 'dialog.errorMessage' | translate }}</mat-error>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10"  class="submit">
    <button fxLayoutAlign="center center" class="secondary"
            [class.disabled]="typeError"
            mat-button
            (click)="postSubmit()">{{ 'forms.send' | translate }}</button>
  </div>
</div>

<div *ngIf="isUpload && type === 'users'" class="image-container filled" fxLayout="column" fxLayoutAlign="center center">
  <p>{{ 'dialog.excel' | translate }}</p>
  <ol>
    <li>name</li>
    <li>surname</li>
  </ol>
  <div class="custom-input-file tertiary-background">
    <input #file class="input-file" type="file" value="" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
           (change)="handleFileInput($event.target.files)" >{{ 'selectFile' | translate }}
  </div>
  <div>{{fileName}}</div>
  <mat-error *ngIf="typeError">{{ 'dialog.errorMessage' | translate }}</mat-error>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10"  class="submit">
    <button fxLayoutAlign="center center" class="secondary"
            [class.disabled]="typeError"
            mat-button
            (click)="postSubmit()">{{ 'forms.send' | translate }}</button>
  </div>
</div>
<div *ngIf="isUpload && type === 'products'" class="image-container filled" fxLayout="column" fxLayoutAlign="center center">
  <p>{{ 'dialog.excel' | translate }}</p>
  <ol>
    <li>Nombre</li>
    <li>Precio</li>
    <li>Categoria</li>
    <li>info</li>
  </ol>
  <div class="custom-input-file tertiary-background">
    <input #file class="input-file" type="file" value="" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
           (change)="handleFileInput($event.target.files)" >{{ 'selectFile' | translate }}
  </div>
  <div>{{fileName}}</div>
  <mat-error *ngIf="typeError">{{ 'dialog.errorMessage' | translate }}</mat-error>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10"  class="submit">
    <button fxLayoutAlign="center center" class="secondary"
            [class.disabled]="typeError"
            mat-button
            (click)="postSubmit()">{{ 'forms.send' | translate }}</button>
  </div>
</div>
<div *ngIf="isUpload && type === 'workers'" class="image-container filled" fxLayout="column" fxLayoutAlign="center center">
  <p>{{ 'dialog.excel' | translate }}</p>
  <ol>
    <li>Nombre</li>
    <li>Apellido</li>
    <li>Usuario</li>
    <li>Password</li>
    <li>Rol</li>
    <li>Categoria</li>
  </ol>
  <div class="custom-input-file tertiary-background">
    <input #file class="input-file" type="file" value="" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
           name="product-img" (change)="handleFileInput($event.target.files)" >{{ 'selectFile' | translate }}
  </div>
  <div>{{fileName}}</div>
  <mat-error *ngIf="typeError">{{ 'dialog.errorMessage' | translate }}</mat-error>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10"  class="submit">
    <button fxLayoutAlign="center center" class="secondary"
            [class.disabled]="typeError"
            mat-button
            (click)="postSubmit()">{{ 'forms.send' | translate }}</button>
  </div>
</div>
<div *ngIf="type === 'invitations'" class="image-container filled" fxLayout="column" fxLayoutAlign="center center">
  <p>{{ 'dialog.excel' | translate }}</p>
  <ol style="list-style-type: upper-latin;">
    <li>email</li>
    <li>name</li>
    <li>surname</li>
  </ol>
  <!--<div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10"  class="submit" style="margin-bottom: 0px !important;">
    <button fxLayoutAlign="center center"
            mat-button style="background-color: #0f223e; color: white;"
            (click)="postSubmit()">Descargar ejemplo</button>
  </div>-->
</div>
<div *ngIf="type === 'photo'" class="image-container filled" fxLayout="column" fxLayoutAlign="center center">
  <p>La imagen a subir debe tener formato .jpg, 4711px de alto y 3313px de acho.</p>
  <!--<div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10"  class="submit" style="margin-bottom: 0px !important;">
    <button fxLayoutAlign="center center"
            mat-button style="background-color: #0f223e; color: white;"
            (click)="postSubmit()">Descargar ejemplo</button>
  </div>-->
</div>
<div *ngIf="isUpload && type === 'qrs'" class="image-container filled" fxLayout="column" fxLayoutAlign="center center">
  <p>{{ 'dialog.qr' | translate }}</p>
  <div class="custom-input-file tertiary-background">
    <input #file class="input-file" type="file" value="" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
           name="product-img" (change)="handleFileInput($event.target.files)" >{{ 'selectFile' | translate }}
  </div>
  <div>{{fileName}}</div>
  <mat-error *ngIf="typeError">{{ 'dialog.errorMessage' | translate }}</mat-error>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10"  class="submit">
    <button fxLayoutAlign="center center" class="secondary"
            [class.disabled]="typeError"
            mat-button
            (click)="postSubmit()">{{ 'forms.send' | translate }}</button>
  </div>
</div>
