import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MaterialModule } from '@shared/angular-material';

import { BracelitConfirmNavigationModule } from '../common/bracelit-confirm-navigation';
import { BracelitFileUploaderModule } from '../common/bracelit-file-uploader';
import { BracelitImagePreviewModule } from '../common/bracelit-image-preview';
import { BracelitPipesModule } from '../common/bracelit-pipes';
import { BracelitPreloaderModule } from '../common/bracelit-preloader';
import { BracelitFormItemModule } from './bracelit-form-item/bracelit-form-item.module';
import { BracelitFormComponent } from './bracelit-form.component';
import { BracelitSelectorModule } from './bracelit-selector/bracelit-selector.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    BracelitFormComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FlexLayoutModule,
    BracelitFileUploaderModule,
    BracelitFormItemModule,
    BracelitSelectorModule,
    BracelitConfirmNavigationModule,
    BracelitSelectorModule,
    BracelitImagePreviewModule,
    BracelitPreloaderModule,
    TranslateModule,
    BracelitPipesModule
  ],
  exports: [
    BracelitFormComponent,
    BracelitFileUploaderModule,
    BracelitFormItemModule,
    BracelitSelectorModule,
    BracelitConfirmNavigationModule,
    BracelitSelectorModule,
    BracelitImagePreviewModule,
    BracelitPreloaderModule
  ]
})
export class BracelitFormModule {
}
