<ng-container *ngIf="insideModal && closeContainerEnabled">
  <div class="close-container">
        <span class="close-box">
            <mat-icon (click)="onClose()" svgIcon="close"></mat-icon>
        </span>
  </div>
</ng-container>
<div [ngClass]="{'bracelit-modal-form': insideModal, 'full-height-container': fullHeight}" class="bracelit-form-container">
  <h2 *ngIf="insideModalTitle" [ngClass]="{'primary-colored-title': primaryColoredTitle, 'centered-title': centeredTitle}"
      class="inside-modal-title">
    {{ insideModalTitle }}</h2>

  <div *ngIf="title || (backEnabled && !insideModal) || (requiredSign && hasRequiredFields && !insideModal)"
       [ngClass]="{'form-without-padding': formWithoutPadding}"
       class="form-header">
    <div class="back-box">
      <div (click)="onBack()" *ngIf="backEnabled && !insideModal" class="back-container">
        <mat-icon svgIcon="chevron-left"></mat-icon>
        {{ backText }}
      </div>
    </div>
    <div *ngIf="title" class="form-title-box">
      <div *ngIf="title"
           [ngClass]="{'primary-colored-title': primaryColoredTitle, 'centered-title': centeredTitle}" class="form-title">
        <span>{{ title }}</span>
      </div>
    </div>
    <div class="tip-box">
      <div *ngIf="requiredSign && hasRequiredFields && !insideModal"
           class="required-fields-tip foreground-secondary-text-color">
        {{ requiredText }}
      </div>
    </div>
  </div>

  <form (keydown.enter)="checkPreventDefault($event)" (ngSubmit)="onSubmit()" [formGroup]="bracelitFormGroup" [fxLayoutGap]="layoutGap"
        [id]="id"
        [ngClass]="{'form-without-padding': formWithoutPadding}"
        [ngStyle]="{'margin-bottom.px': extraMarginBottom}" style="justify-content: center;">

    <ng-content></ng-content>

    <div *ngIf="error" class="center-align warn-text error-message" fxFlex="100">{{ errorMessage }}</div>

    <span *ngIf="requiredSign && hasRequiredFields && insideModal"
          class="required-fields-tip foreground-secondary-text-color">
               {{ requiredText }}
    </span>

    <div *ngIf="showButtons" class="button-container" fxLayout="row wrap"
         fxLayoutAlign="space-between center">

      <button (click)="onCancel()" *ngIf="cancelButtonText" [color]="cancelButtonColor" id="cancelButton" mat-button>
        {{ cancelButtonText }}
      </button>

      <button (click)="onEdit()"
              *ngIf="editableForm && formDisabled && method == 'patch' && editEnabled" [color]="submitButtonColor" mat-raised-button>
        <mat-icon svgIcon="pencil"></mat-icon>
        {{ editButtonText }}
      </button>

      <ng-container *ngIf="!editableForm || !formDisabled">
        <!-- Delete Button -->
        <button (click)="openConfirmDelete()" *ngIf="method == 'patch' && deleteEnabled" [color]="deleteButtonColor" id="delete-button"
                mat-button type="button">
          <mat-icon *ngIf="buttonIconsEnabled" [svgIcon]="deleteButtonIcon"></mat-icon>
          {{ deleteButtonText }}
        </button>
        <!-- Save/Edit Button -->
        <button *ngIf="editEnabled && !submitting && !primaryRaisedButton" [@submitState]="submitAnimationEnabled && (bracelitFormGroup.valid ? 'valid' : 'invalid')" [color]="submitButtonColor"
                [disabled]="disabledClauses"
                [id]="id+'submit-button'"
                [ngClass]="{'disabled-submit': !bracelitFormGroup.valid || disabledClauses}"
                class="save-button {{submitButtonClasses}}"
                mat-button>
          <mat-icon *ngIf="method == 'post' && buttonIconsEnabled" svgIcon="download"></mat-icon>
          <mat-icon *ngIf="method == 'patch' && buttonIconsEnabled" svgIcon="pencil"></mat-icon>
          {{ saveButtonText }}
          <span *ngIf="!bracelitFormGroup.valid && requiredButtonSign" class="invalid-form">*</span>
        </button>

        <button *ngIf="editEnabled && !submitting && primaryRaisedButton" [@submitState]="submitAnimationEnabled && (bracelitFormGroup.valid ? 'valid' : 'invalid')"
                [color]="submitButtonColor"
                [disabled]="disabledClauses"
                [id]="id+'submit-button'"
                [ngClass]="{'disabled-submit': !bracelitFormGroup.valid || disabledClauses}"
                class="save-button {{submitButtonClasses}}"
                mat-raised-button>
          <mat-icon *ngIf="method == 'post' && buttonIconsEnabled" svgIcon="download"></mat-icon>
          <mat-icon *ngIf="method == 'patch' && buttonIconsEnabled" svgIcon="pencil"></mat-icon>
          {{ saveButtonText }}
          <span *ngIf="!bracelitFormGroup.valid && requiredButtonSign" class="invalid-form">*</span>
        </button>

        <mat-spinner *ngIf="submitting" [diameter]="36"></mat-spinner>

      </ng-container>
    </div>
  </form>
</div>
