import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxUploaderModule } from 'ngx-uploader';

import { MaterialModule } from '@shared/angular-material';

import { BracelitDirectivesModule } from '@bracelit-components/common/bracelit-directives/bracelit-directives.module';
import { BracelitPreloaderModule } from '@app/shared/bracelit-components/common/bracelit-preloader';

import { BracelitCropperComponent } from './bracelit-cropper/bracelit-cropper.component';
import { BracelitFileUpLoaderComponent } from './bracelit-file-uploader.component';

@NgModule({
  declarations: [
    BracelitFileUpLoaderComponent,
    BracelitCropperComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    NgxUploaderModule,
    ImageCropperModule,
    BracelitPreloaderModule,
    BracelitDirectivesModule,
    FlexLayoutModule
  ],
  exports: [
    BracelitFileUpLoaderComponent,
    BracelitCropperComponent,
    NgxUploaderModule,
    ImageCropperModule,
  ]
})
export class BracelitFileUploaderModule {
}
