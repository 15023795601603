import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BracelitImagePreviewComponent } from './bracelit-image-preview.component';

@NgModule({
  declarations: [
    BracelitImagePreviewComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    BracelitImagePreviewComponent
  ]
})
export class BracelitImagePreviewModule { }
