import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MaterialModule } from '@shared/angular-material';

import { BracelitPreloaderModule } from '@app/shared/bracelit-components/common/bracelit-preloader/bracelit-preloader.module';

import { BracelitWizardHelpComponent } from './bracelit-wizard-help.component';

@NgModule({
  declarations: [
    BracelitWizardHelpComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    BracelitPreloaderModule,
    MaterialModule
  ],
  exports: [
    BracelitWizardHelpComponent
  ]
})
export class BracelitWizardHelpModule { }
