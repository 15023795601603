import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';

import { ApiService } from '../../services/api.service';
import { submitStateTrigger } from './animations';

/**
 * BracelitWizardForm
 * @ignore
 */
@Component({
  selector: 'bracelit-wizard-form',
  templateUrl: './bracelit-wizard-form.component.html',
  styleUrls: ['./bracelit-wizard-form.component.scss'],
  animations: [submitStateTrigger]
})
export class BracelitWizardFormComponent implements OnInit, OnChanges {
  @Input() formGroup: UntypedFormGroup;
  @Input() id: string = 'form-id-' + Math.floor((Math.random() * 1000) + 1);

  @Input() triggerSubmit = false;
  @Input() disabledClauses: any = null;
  @Input() apiRoute = '';
  @Input() method = 'post';
  @Input() saveButtonEnabled = true;
  @Input() saveButtonText = 'GUARDAR CAMBIOS';
  @Input() preventEnterSubmit = false;
  @Input() toastMessage = 'Formulario guardado con éxito';
  @Input() errorMessage = 'Error al guardar el formulario';
  @Input() formDisabled = false;
  @Input() titlePrefix = '';
  @Input() formTitle = '';
  @Input() requiredSign = false;
  @Input() requiredText = '* Campos obligatorios';
  /* Navigation inputs */
  @Input() nextFormUrl = '';
  @Input() nextFormButtonText = 'SIGUIENTE';
  @Input() nextFormName = '';
  @Input() previousFormUrl = '';
  @Input() previousFormButtonText = 'ANTERIOR';
  @Input() previousFormName = '';
  @Input() wizardNavigateUrl = '';
  /* Toggle inputs */
  @Input() toggleEnabled = true;
  @Input() toggleChecked = true;
  @Input() toggleButtonText = 'TEORÍA';
  @Input() loadingFormText = 'Generando formulario...';
  @Input() loaded = true;
  @Input() autoSave = false;
  @Input() formWithPadding = true;
  /* {name: 'Porter', link: 'porter'}, {name: 'BCG', link: 'bcgMatrix'} */
  @Input() warningText = '¡Cuidado!';
  @Input() thisFormText = 'Este formulario ';
  @Input() noRealDataText = 'NO contiene datos reales';
  @Input() unloadedDependenciesText = ', para poder realizarlo y guardarlo correctamente completa antes los siguientes formularios:';
  @Input() unloadedDependenciesObject: object[] = [];
  @Input() updatingDependencies = false;
  @Input() updatingDependenciesProgress = 0;
  @Input() updatingDependenciesMode = 'determinate';
  @Input() updatingDependenciesText = 'Actualizando formularios...';
  @Input() templateRef: TemplateRef<any>;
  @Input() lazyLoaded = false;
  /* Export inputs */
  @Input() exportButtonText = 'EXPORTAR';
  @Input() exportEnabled = false;
  @Input() exportButtonDisabled = false;

  @Output() readonly wizardPreNavigatingFunction: EventEmitter<any> = new EventEmitter();
  @Output() readonly preSubmitFunction: EventEmitter<any> = new EventEmitter();
  @Output() readonly postSubmitFunction: EventEmitter<any> = new EventEmitter();
  @Output() readonly toggleChange: EventEmitter<any> = new EventEmitter();
  @Output() readonly export: EventEmitter<any> = new EventEmitter();

  submitting = false;
  error: Error = null;
  hasRequiredFields = false;
  initialFormGroupValue: any;

  constructor(private router: Router,
              private apiService: ApiService,
              private formBuilder: UntypedFormBuilder,
              private route: ActivatedRoute,
              public snackBar: MatSnackBar) {
  }

  ngOnInit() {
    window.scroll(0, 0);
    if (this.formDisabled) {
      this.formGroup.disable();
    }
    if (this.requiredSign) {
      if (this.checkIfRequired(this.formGroup)) {
        this.hasRequiredFields = true;
      }
    }
  }

  ngOnChanges($event: SimpleChanges) {
    if (!this.initialFormGroupValue) {
      if ($event['loaded']) {
        if (($event['loaded'].previousValue === false || !$event['loaded'].previousValue) && $event['loaded'].currentValue === true) {
          this.initialFormGroupValue = this.formGroup.value;
        }
      }
    }
    if ($event['wizardNavigateUrl']) {
      if (($event['wizardNavigateUrl'].previousValue === '' || !$event['wizardNavigateUrl'].previousValue) && $event['wizardNavigateUrl'].currentValue) {
        this.openSnackBar(this.toastMessage);
        this.navigate(this.wizardNavigateUrl);
      }
    }
    if ($event['triggerSubmit']) {
      if (($event['triggerSubmit'].previousValue === false && $event['triggerSubmit'].currentValue === true)
        || ($event['triggerSubmit'].previousValue === true && $event['triggerSubmit'].currentValue === false)) {
        this.onSubmit(true);
      }
    }
  }

  onSubmit(openSnackBar = false) {
    if (!this.formGroup.valid) {
      this.markFormGroupDirty(this.formGroup);
    } else if (this.unloadedDependenciesObject.length === 0) {
      if (!this.submitting) {
        this.preSubmitFunction.emit();
        this.submitting = true;
        this.apiService[this.method](this.apiRoute, this.formGroup.value).subscribe(
          response => {
            if (openSnackBar) {
              this.openSnackBar(this.toastMessage);
            }
            this.initialFormGroupValue = this.formGroup.value;
            const id = response.id;
            if (id && !this.formGroup.controls[id]) {
              const control = new UntypedFormControl(id);
              this.formGroup.addControl('id', control);
            }
            this.postSubmitFunction.emit(this.formGroup.value);
            this.submitting = false;
          },
          error => {
            this.error = error as any;
            this.submitting = false;
          });
      }
    }
  }

  onNavigate(formUrl: string = '') {
    this.wizardPreNavigatingFunction.emit(formUrl);
    if ((JSON.stringify(this.initialFormGroupValue) !== JSON.stringify(this.formGroup.value) || this.autoSave)
      && this.unloadedDependenciesObject.length === 0) {
      this.onSubmit();
    } else {
      this.navigate(formUrl);
    }
  }

  navigate(formUrl: string = '') {
    if (this.lazyLoaded) {
      this.router.navigate(['../' + formUrl], {relativeTo: this.route.parent});
    } else {
      this.router.navigate(['../' + formUrl], {relativeTo: this.route});
    }
  }

  onDependency(link: string) {
    if (this.lazyLoaded) {
      this.router.navigate(['../' + link], {relativeTo: this.route.parent});
    } else {
      this.router.navigate(['../' + link], {relativeTo: this.route});
    }
  }

  checkPreventDefault($e: any) {
    if (this.preventEnterSubmit) {
      $e.preventDefault();
    }
  }

  private markFormGroupDirty(formGroup: UntypedFormGroup) {
    (Object as any).values(formGroup.controls).forEach(control => {
      if (control instanceof UntypedFormGroup) {
        this.markFormGroupDirty(control);
      } else {
        (control.controls) ? (Object as any).values(control.controls).forEach(c => this.markFormGroupDirty(c)) : control.markAsDirty();
      }
    });
  }

  private checkIfRequired(abstractControl: AbstractControl) {
    if (abstractControl.validator) {
      const validator = abstractControl.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }
    if (abstractControl['controls']) {
      for (const controlName in abstractControl['controls']) {
        if (abstractControl['controls'][controlName]) {
          if (this.checkIfRequired(abstractControl['controls'][controlName])) {
            return true;
          }
        }
      }
    }

    return false;
  }

  onToggleChange(event: MatSlideToggleChange) {
    this.toggleChecked = event.checked;
    this.toggleChange.emit(this.toggleChecked);
  }

  onExport() {
    if (this.exportEnabled) {
      this.export.emit(true);
    }
  }

  openSnackBar(message: string, action: string = '') {
    setTimeout(() => {
      this.snackBar.open(message, action, {
        duration: 2000,
        panelClass: ['primary-background', 'center-align']
      });
    });
  }
}
