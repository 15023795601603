import { BracelitModel } from '../abstract/bracelit-model';

/**
 * SavitarFile class.
 */
export class SavitarFile extends BracelitModel {
  /** The name of the file. */
  name: string;
  /** The path of the file. */
  path: string;
  /** The url of the file. */
  url: string;
  /** The category of the file. */
  category?: string;
  /** The extension of the file. (pdf, png, doc...) */
  extension: string;
  /** The number of pages of the file. */
  pages?: number;
  /** The size of the file. */
  size: number;
}
