import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { EventModel } from '@app/events/event.model'
import { MatDialogRef, MAT_DIALOG_DATA }  from '@angular/material/dialog';
import { NgForm, UntypedFormBuilder } from '@angular/forms';
import { DataArrayService } from '../events.service';
import {FormControl, Validators} from '@angular/forms';
import { RouterEvent, RouterLink } from '@angular/router';
import { format } from 'path';
import { DataStorageService } from '@app/shared/services/data-storage.service';
import esJson from '../../../assets/i18n/es.json';
import enJson from '../../../assets/i18n/en.json';
import { ThemeService } from '@app/shared/services';



@Component({
  selector: 'app-events-form',
  templateUrl: './events-form.component.html',
  styleUrls: ['./events-form.component.scss']
})


export class EventsFormComponent implements OnInit {

  langJson = (localStorage.getItem('lang') === 'es') ? esJson : enJson;
  minDate: Date;
  maxDate: Date;
  selectedTheme: any;
  uploadForm: any;
  imgURL: any;
  selectedColor: any;

  constructor(
    public dialogRef: MatDialogRef<EventsFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data_form: any,
    private dataArrayService: DataArrayService,
    public dataStorageService: DataStorageService,
    private formBuilder: UntypedFormBuilder,
    public themeService: ThemeService
    ) {
      const currentYear = new Date().getFullYear();
      const currentDate = new Date().toString();
      this.minDate = new Date(currentDate);
      this.maxDate = new Date(currentYear + 20, 11, 31);
    }

  ngOnInit(): void {
    this.uploadForm = this.formBuilder.group({
      profile: ['']
    });
  }

  @Output() newItem = new EventEmitter<EventModel>();
  nuevoItem: EventModel = {
    id: '',
    name: '',
    startingDate: '',
    endingDate: '',
    returnMoneyCommission: 0,
    selectedTheme: '',
    file: '',
    startMoneyReturn: '',
    endMoneyReturn: '',
    isTicketScan: '',
    hasNotifications: '',
    attendees: '',
    hasVouchers: '',
    hasAccessControl: '',
    isMoneyReturned: '',
    hasRegistration: '',
    hasExactPurchases: '',
    hasPurchases: '',
    hasTpvCash: '',
    hasTpvCard: '',
    hasTpvWristband: '',
    hasOnlyVouchers: '',
    hasQR: '',
    hasPreCharge: '',
  };

  agregarItem() {
    this.newItem.emit(this.nuevoItem);
    this.nuevoItem = {
      id: '',
      name: '',
      startingDate: '',
      endingDate: '',
      startMoneyReturn: '',
      endMoneyReturn: '',
      returnMoneyCommission: '',
      selectedTheme: '',
      file: '',
      attendees: '',
      isTicketScan: '',
      hasNotifications: '',
      hasVouchers: '',
      hasAccessControl: '',
      isMoneyReturned: '',
      hasRegistration: '',
      hasExactPurchases: '',
      hasPurchases: '',
      hasTpvCash: '',
      hasTpvCard: '',
      hasTpvWristband: '',
      hasOnlyVouchers: '',
      hasQR: '',
      hasPreCharge: '',
    };
  }


  nuevoDato: any = {};

  onSubmit(form: NgForm) {
    if (!form.valid){
      return;
    }
    const nombre = form.value.nombre.trim();
    const startingDate = form.value.startingDate._d;
    startingDate.setHours(startingDate.getHours() + 3);
    const endingDate = form.value.endingDate._d;
    endingDate.setHours(endingDate.getHours() + 3);
    const startMoneyReturn = form.value.startMoneyReturn._d;
    startMoneyReturn.setHours(startMoneyReturn.getHours() + 3);
    const endMoneyReturn = form.value.endMoneyReturn._d;
    endMoneyReturn.setHours(endMoneyReturn.getHours() + 3);
    const returnMoneyCommission = form.value.returnMoneyCommission;
    const attendees = form.value.attendees;
    const selectedTheme = this.selectedTheme;
    const file = (this.imgURL !== undefined) ? this.uploadForm.get('profile').value : '';
    const isTicketScan = form.value.isTicketScan;
    const hasNotifications = form.value.hasNotifications;
    const hasVouchers = form.value.hasVouchers;
    const hasAccessControl = form.value.hasAccessControl;
    const hasRegistration = form.value.hasRegistration;
    const hasExactPurchases = form.value.hasExactPurchases;
    const hasPurchases = form.value.hasPurchases;
    const hasTpvCash = form.value.hasTpvCash;
    const hasTpvCard = form.value.hasTpvCard;
    const hasTpvWristband = form.value.hasTpvWristband;
    const hasOnlyVouchers = form.value.hasOnlyVouchers;
    const hasQR = form.value.hasQR;
    const hasPreCharge = form.value.hasPreCharge;

    this.nuevoDato = {
      name: nombre,
      startingDate: startingDate,
      endingDate: endingDate,
      startMoneyReturn: startMoneyReturn,
      endMoneyReturn: endMoneyReturn,
      returnMoneyCommission: returnMoneyCommission,
      attendees: attendees,
      selectedTheme: selectedTheme,
      file: file,
      isTicketScan: isTicketScan,
      hasNotifications: hasNotifications,
      hasVouchers: hasVouchers,
      hasAccessControl: hasAccessControl,
      hasRegistration: hasRegistration,
      hasExactPurchases: hasExactPurchases,
      hasPurchases: hasPurchases,
      hasTpvCash: hasTpvCash,
      hasTpvCard: hasTpvCard,
      hasTpvWristband: hasTpvWristband,
      hasOnlyVouchers: hasOnlyVouchers,
      hasQR: hasQR,
      hasPreCharge: hasPreCharge,
    };

    let nuevoDatoFiltrado = {};
    for (let key in this.nuevoDato) {
      if (this.nuevoDato[key] !== "" && this.nuevoDato[key] !== undefined) {
        nuevoDatoFiltrado[key] = this.nuevoDato[key];
      }
    }

    this.dataStorageService
      .createEventWithAccount(nuevoDatoFiltrado)
      .subscribe( response => {
        this.dataArrayService.addData(this.nuevoDato);
        this.dialogRef.close(this.nuevoDato);
      });
  }

  onClose(){
    this.dialogRef.close();
  }

  putTheme(event) {
    this.selectedTheme = event.theme;
    this.selectedColor = event.primary;
  }

  selectTheme(theme){
    this.selectedTheme = theme.theme;
    this.selectedColor = theme.primary;
  }

  preview(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.uploadForm.get('profile').setValue(file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_event) => {
        this.imgURL = reader.result;
    };
    }
  }

  name_control = new FormControl('', [Validators.required]);

  getErrorMessage_name() {
    if (this.name_control.hasError('required')) {
      return this.langJson.events.formRequiredField;
    }
  }

  attendees_control = new FormControl('', [Validators.required]);

  getErrorMessage_attendees() {
    if (this.attendees_control.hasError('required')) {
      return this.langJson.events.formRequiredField;
    }
  }

  commision_control = new FormControl('', [Validators.required]);

  getErrorMessage_commision() {
    if (this.commision_control.hasError('required')) {
      return this.langJson.events.formRequiredField;
    }
  }


  date_control = new FormControl('', [Validators.required]);

  getErrorMessage_init_date() {
    if (this.date_control.hasError('required')) {
      return this.langJson.events.formRequiredField;
    }
  }

  return_control = new FormControl('', [Validators.required]);

  getErrorMessage_return_date() {
    if (this.return_control.hasError('required')) {
      return this.langJson.events.formRequiredField;
    }
  }

}
