<h2 class="primary-color" fxLayoutAlign="center center">Cancelación del pedido</h2>

<div mat-dialog-content style="color: #0F223E; margin-bottom: 10px" fxLayoutAlign="center center">
  Indique el motivo de la cancelación del pedido
</div>
<textarea name="textarea" rows="3" cols="50" style="max-width: 98%; min-width: 98%; margin-bottom: 20px" [(ngModel)] = text></textarea>

<div mat-dialog-content style="color: #0F223E; margin-bottom: 20px" fxLayoutAlign="center center">
  {{message}}
</div>

<div mat-dialog-actions fxLayoutAlign="space-between center">
  <button mat-button class="default primary-color" style="width: 48%;" (click)="onNoClick()">{{ cancel }}</button>
  <button mat-button class="default" style="background-color: #18a1b9 !important; color:white; width: 48%;" (click)="onContinue()" [mat-dialog-close]="true">{{ yes }}</button>
</div>
