import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MaterialModule } from '@shared/angular-material';

import { BracelitConfirmNavigationComponent } from './bracelit-confirm-navigation.component';

@NgModule({
  declarations: [
    BracelitConfirmNavigationComponent
  ],
    imports: [
        CommonModule,
        MaterialModule,
        FlexLayoutModule
    ],
  exports: [
    BracelitConfirmNavigationComponent
  ]
})
export class BracelitConfirmNavigationModule { }
