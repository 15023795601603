import { Injectable } from '@angular/core';

import { Product } from '@shared/models/product';

@Injectable()
export class DashboardService {
  public ticketCount: number;
  public ticketCountValidated: number;
  public roomPending: any[];
  private infoprod: { name: any; quantity: any; waiter: any };
  private prod: any[] = [];
  private waiters: any[] = [];
  private infowaiter: {name: any; rol: any; recharges: any; sells: any; rechargesVouchers: any; sellsVouchers: any };
  private bookMV: any = [{
    nameBook: '',
    quantity: ''
  },
    {
      nameBook: '',
      quantity: ''
    },
    {
      nameBook: '',
      quantity: ''
    }
  ];
  private categoryBookMV: any = [{
    nameBook: '',
    quantity: '',
    price: ''
  }
  ];
  private prodName = [];
  private prodQuantity = [];
  private prodPrice = [];
  private bookName = [];
  private bookQuantity = [];
  private bookPrice = [];
  private categoryBookName = [];
  private categoryBookQuantity = [];
  private categoryBookPrice = [];
  private products: Product[] = [];
  private categories: any[] = [];
  private workers: any[] = [];
  private rooms: any[] = [];
  private events: Event[] = [];
  private activities: any[] = [];
  public moves: any[] = [];
  public movesId: any;
  public movesIdCopy: any;
  public l: any;
  public workersId: any;
  public waiterName: any[];
  public waiterRecharges: any[];
  public waiterSells: any[];
  public waiterRol: any[];
  public waiterVouchersRecharges: any[];
  public waiterVouchersSells: any[];
  public waiter: any[];
  eventIdGestor: string;

  setEvents(events: Event[]) {
    this.events = events;

    return this.events;
  }

  getEvents() {
    return this.events;
  }

  setProducts(products: Product[]) {
    this.products = products;

    return this.products;
  }

  getProducts() {
    return this.products.slice();
  }

  setCategories(categories) {
    this.categories = categories;

    return this.categories;
  }

  getCategories() {
    return this.categories.slice();
  }

  setWorkers(workers) {
    this.workers = workers;

    return this.workers;
  }

  getWorkers() {
    return this.workers['staffmembersInfo'].slice();
  }

  setRooms(rooms) {
    this.rooms = rooms;

    return this.rooms;
  }

  getRooms() {
    return this.rooms.slice();
  }

  setActivities(activities) {
    this.activities = activities;

  }

  getActivities() {
    return this.activities.slice();
  }

  addCategoriesBookingsBestSell(books) {
    this.categoryBookName = [];
    this.categoryBookQuantity = [];
    this.categoryBookPrice = [];
    for(let book of books) {
      if (/\d/.test(book.nameBook)) {
        const bookWithoutNumber = book.nameBook.replace((book.nameBook).match(/\d+/g), '');
        if (this.categoryBookName.indexOf(bookWithoutNumber) === -1) {
          this.categoryBookName.push(bookWithoutNumber);
          this.categoryBookQuantity.push(String(parseInt(book.quantity)));
          this.categoryBookPrice.push(String(parseFloat(book.price)));
        } else {
          this.categoryBookQuantity[this.categoryBookName.indexOf(bookWithoutNumber)] = String(parseInt(this.categoryBookQuantity[this.categoryBookName.indexOf(bookWithoutNumber)]) + parseInt(book.quantity));
          this.categoryBookPrice[this.categoryBookName.indexOf(bookWithoutNumber)] = String(parseFloat(this.categoryBookPrice[this.categoryBookName.indexOf(bookWithoutNumber)]) + (parseFloat(book.price)));
        }
      } else {
        if (this.categoryBookName.indexOf(book.nameBook) === -1) {
          this.categoryBookName.push(book.nameBook);
          this.categoryBookQuantity.push(String(parseInt(book.quantity)));
          this.categoryBookPrice.push(String(parseFloat(book.price)));
        } else {
          this.categoryBookQuantity[this.categoryBookName.indexOf(book)] = String(parseInt(this.categoryBookQuantity[this.categoryBookName.indexOf(book.nameBook)]) + parseInt(book.quantity));
          this.categoryBookPrice[this.categoryBookName.indexOf(book)] = String(parseFloat(this.categoryBookPrice[this.categoryBookName.indexOf(book.nameBook)]) + (parseFloat(book.price)));
        }
      }
    }
    let count = 0;
    let ii = 0;
    while (count < 1) {
      let max = parseInt(this.categoryBookQuantity[0]);
      let maxIndex = 0;

      for (let i = 1; i < this.categoryBookQuantity.length; i++) {
        if (parseInt((this.categoryBookQuantity)[i]) > max) {
          maxIndex = i;
          max = parseInt((this.categoryBookQuantity)[i]);
        }
      }
      this.categoryBookMV[count].nameBook = this.categoryBookName[maxIndex];
      this.categoryBookMV[count].quantity = this.categoryBookQuantity[maxIndex];
      this.categoryBookMV[count].price = this.categoryBookPrice[maxIndex];
      ii = this.categoryBookQuantity.indexOf(String(max));
      if (ii >= 0) {
        this.categoryBookName.splice(ii, 1);
        this.categoryBookQuantity.splice(ii, 1);
        this.categoryBookPrice.splice(ii, 1);
      }
      count += 1;
    }

    return this.categoryBookMV;
  }

  setInfoTickets(data: any) {
    this.ticketCount = data.count;
    this.ticketCountValidated = data.countValidated;
  }

  setEventIdGestor(eventIdGestor: string){
    this.eventIdGestor = eventIdGestor;
  }

  getEventIdGestor(){
    return this.eventIdGestor
  }
}
