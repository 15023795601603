<div>
  <h2 mat-dialog-title class="primary-color" fxLayoutAlign="center center">{{tittle}}</h2>
  <mat-icon class="secondary-fill"
            svgIcon="close"
            style="width: 10%; float: right; margin-top: -50px; position: relative; cursor: pointer"
            (click)="onClose()"></mat-icon>
</div>
<div mat-dialog-content>
  <form [formGroup]="myForm" class="login-form-container light-22px-center-white" style="width: 100% !important; display: flex; justify-content: left; flex-direction: column"
  (ngSubmit)="onSubmit()">

    <label [for]="'email'" class="primary-color" style="margin-right: 0px; font-size: 16px; width: 100%; text-align: left">
      Email: </label>
    <div
    style="display: flex; justify-content: center; align-items: center; margin-bottom: 15px; width: 100%; height: 38px; border-radius: 5px; border: 1px solid #88A9BB;">
      <input
        style="width: 100%; height: 90%; border: none; background-color: rgba(240, 248, 255, 0); color: black; outline: none; padding: 0 10px; font-size: 16px"
        id='device-staff'
        placeholder="Email"
        type="text"
        formControlName='email'>
    </div>
  </form>
</div>

<div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10">

  <button class="secondary"
          [class.disabled]="!myForm.valid"
          mat-button
          (click)="onSubmit()">{{asociar}}</button>
</div>
