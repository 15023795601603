import { MatPaginatorIntl } from '@angular/material/paginator';
import esJson from '../../../../assets/i18n/es.json';
import enJson from '../../../../assets/i18n/en.json';

/**
 * Spanish paginator range label
 * @param page
 * @param pageSize
 * @param length
 */
const spanishRangeLabel = (page: number, pageSize: number, length: number) => {
  if (length === 0 || pageSize === 0) {
    return `0 de ${length}`;
  }

  length = Math.max(length, 0);

  const startIndex = page * pageSize;

  // If the start index exceeds the list length, do not try and fix the end index to the end.
  const endIndex = startIndex < length ?
    Math.min(startIndex + pageSize, length) :
    startIndex + pageSize;

  return `${startIndex + 1} - ${endIndex} de ${length}`;
};

/**
 * Spanish paginator intl
 * @returns {MatPaginatorIntl}
 */
export function getSpanishPaginatorIntl() {
  const paginatorIntl = new MatPaginatorIntl();
  const langJson = (localStorage.getItem('lang') === 'es') ? esJson : enJson;
  paginatorIntl.itemsPerPageLabel = langJson.paginator.elements;
  paginatorIntl.nextPageLabel = langJson.paginator.nextPage;
  paginatorIntl.previousPageLabel = langJson.paginator.previousPage;
  paginatorIntl.firstPageLabel = langJson.paginator.firstPage;
  paginatorIntl.lastPageLabel = langJson.paginator.lastPage;
  paginatorIntl.getRangeLabel = spanishRangeLabel;

  return paginatorIntl;
}
