import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BracelitAltPipe } from '@bracelit-components/common/bracelit-pipes/bracelit-alt.pipe';
import { BracelitArrayChangeDetectorPipe } from '@bracelit-components/common/bracelit-pipes/bracelit-array-change-detector.pipe';
import { BracelitCurrencyPipe } from '@bracelit-components/common/bracelit-pipes/bracelit-currency.pipe';
import { BracelitDatePipe } from '@bracelit-components/common/bracelit-pipes/bracelit-date.pipe';
import { BracelitParenthesisNumberPipe } from '@bracelit-components/common/bracelit-pipes/bracelit-parenthesis-number.pipe';
import { BracelitSafeHtmlPipe } from '@bracelit-components/common/bracelit-pipes/bracelit-safe-html.pipe';

@NgModule({
  declarations: [
    BracelitAltPipe,
    BracelitArrayChangeDetectorPipe,
    BracelitCurrencyPipe,
    BracelitDatePipe,
    BracelitParenthesisNumberPipe,
    BracelitSafeHtmlPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    BracelitAltPipe,
    BracelitArrayChangeDetectorPipe,
    BracelitCurrencyPipe,
    BracelitDatePipe,
    BracelitParenthesisNumberPipe,
    BracelitSafeHtmlPipe
  ]
})
export class BracelitPipesModule { }
