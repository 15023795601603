import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BracelitBackgroundImageDirective } from '@bracelit-components/common/bracelit-directives/bracelit-background-image.directive';
import { BracelitCanvasDrawDirective } from '@bracelit-components/common/bracelit-directives/bracelit-canvas-draw.directive';
import { BracelitDecimalsDirective } from '@bracelit-components/common/bracelit-directives/bracelit-decimals-directive';
import { BracelitLongPressDirective } from '@bracelit-components/common/bracelit-directives/bracelit-long-press.directive';
import { BracelitPositiveNumberDirective } from '@bracelit-components/common/bracelit-directives/bracelit-positive-number.directive';

@NgModule({
  declarations: [
    BracelitBackgroundImageDirective,
    BracelitDecimalsDirective,
    BracelitLongPressDirective,
    BracelitPositiveNumberDirective,
    BracelitCanvasDrawDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    BracelitBackgroundImageDirective,
    BracelitDecimalsDirective,
    BracelitLongPressDirective,
    BracelitPositiveNumberDirective,
    BracelitCanvasDrawDirective
  ]
})
export class BracelitDirectivesModule { }
