import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from '@shared/angular-material';

import { BracelitPreloaderModule } from '@app/shared/bracelit-components/common/bracelit-preloader';

import { BracelitWizardFormComponent } from './bracelit-wizard-form.component';

@NgModule({
  declarations: [
    BracelitWizardFormComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    BracelitPreloaderModule,
    ReactiveFormsModule
  ],
  exports: [
    BracelitWizardFormComponent
  ]
})
export class BracelitWizardFormModule { }
