import { Component, Input } from '@angular/core';

/**
 * BracelitAlertComponent, used for alerts.
 *
 * @example
 *
 * <bracelit-alert type="error"></bracelit-alert>
 */
@Component({
  selector: 'bracelit-alert',
  templateUrl: './bracelit-alert.component.html',
  styleUrls: ['./bracelit-alert.component.scss'],

})
export class BracelitAlertComponent {
  /**
   * Type of the alert
   * Options: info, success, warn, error
   * @type {string}
   */
  @Input() type = 'info';

  /**
   * Controls if the alert is closed
   * @type {boolean}
   */
  hidden = false;

  /**
   * Closes the alert
   */
  closeAlert() {
    this.hidden = true;
  }
}
