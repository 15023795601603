<div id="footer-container" fxLayout="row" fxLayoutAlign="space-around">
    <div class="scroller">
      <div *ngFor="let option of options; let i = index"
            [class.active]="activeTab === actives[i]"
            class="option"
            fxLayout="column"
            fxLayoutAlign="center center"
            [routerLink]=components[i]>
        <mat-icon svgIcon="{{icons[i]}}"></mat-icon>
        <span>{{option}}</span>
      </div>
    </div>
  <div class="arrow">
    ->
  </div>
</div>

