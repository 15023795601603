import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppGuard } from '@shared/guards';
import { AppCustomPreloader } from '@shared/preloader/app-custom-preloader';

import { Bracelit404Component } from '@app/shared/bracelit-components/bracelit-404';

const routes: Routes = [
  {path: '', redirectTo: '/login', pathMatch: 'full'},
  {path: 'login', loadChildren: () => import('app/auth/login/login.module').then(m => m.LoginModule)},
  {
    path: 'dashboard',
    loadChildren: () => import('app/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AppGuard]
  },
  {
    path: 'movimientos',
    loadChildren: () => import('app/profits/profits.module').then(m => m.ProfitsModule),
    canActivate: [AppGuard]
  },
  {
    path: 'productos',
    loadChildren: () => import('app/products/products.module').then(m => m.ProductsModule),
    canActivate: [AppGuard]
  },
  {
    path: 'trabajadores',
    loadChildren: () => import('app/workers/workers.module').then(m => m.WorkersModule),
    canActivate: [AppGuard]
  },
  {
    path: 'bonos',
    loadChildren: () => import('app/voucher/voucher.module').then(m => m.VoucherModule),
    canActivate: [AppGuard]
  },
  {
    path: 'recintos',
    loadChildren: () => import('app/rooms/rooms.module').then(m => m.RoomsModule),
    canActivate: [AppGuard]
  },
  {
    path: 'usuarios',
    loadChildren: () => import('app/users/users.module').then(m => m.UsersModule),
    canActivate: [AppGuard]
  },
  {
    path: 'confEvent',
    loadChildren: () => import('app/confEvent/confEvent.module').then(m => m.ConfEventModule),
    canActivate: [AppGuard]
  },
  {
    path: 'recharge',
    loadChildren: () => import('app/shopping-cart/shopping-cart.module').then(m => m.ShoppingCartModule),
    canActivate: [AppGuard]
  },
  {
    path: 'recharge/cancelar',
    loadChildren: () => import('app/shopping-cart/shopping-cart.module').then(m => m.ShoppingCartModule),
    canActivate: [AppGuard]
  },
  {
    path: 'resultado-pago',
    loadChildren: () => import('app/shopping-cart/payment-feedback/payment-feedback.module').then(m => m.PaymentFeedbackModule),
    canActivate: [AppGuard]
  },
  {
    path: ':status/resultado-pago',
    loadChildren: () => import('app/shopping-cart/payment-feedback/payment-feedback.module').then(m => m.PaymentFeedbackModule),
    canActivate: [AppGuard]
  },
  {
    path: 'pedidos',
    loadChildren: () => import('app/orders/orders.module').then(m => m.OrdersModule),
    canActivate: [AppGuard]
  },
  {
    path: 'actividades',
    loadChildren: () => import('app/activities/activities.module').then(m => m.ActivitiesModule),
    canActivate: [AppGuard]
  },
  {
    path: 'grupos',
    loadChildren: () => import('app/groups/groups.module').then(m => m.GroupsModule),
    canActivate: [AppGuard]
  },
  {
    path: 'consultas',
    loadChildren: () => import('app/data-base/data-base.module').then(m => m.DataBaseModule),
    canActivate: [AppGuard]
  },
  {
    path: 'dispositivos',
    loadChildren: () => import('app/devices/devices.module').then(m => m.DevicesModule),
    canActivate: [AppGuard]
  },
  {
    path: 'crear',
    loadChildren: () => import('app/create/create.module').then(m => m.CreateModule),
    canActivate: [AppGuard]
  },
  {
    path: 'eventos',
    loadChildren: () => import('app/events/events.module').then(m => m.EventsModule),
    canActivate: [AppGuard]
  },
  {
    path: 'notificaciones',
    loadChildren: () => import('app/notifications/notifications.module').then(m => m.NotificationsModule),
    canActivate: [AppGuard]
  },
  {
    path: 'leads',
    loadChildren: () => import('app/lead-scanner/lead-scanner.module').then(m => m.LeadScannerModule),
    canActivate: [AppGuard]
  },
  {path: '**', component: Bracelit404Component, data: {animation: {page: 'notFoundPage'}}}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: AppCustomPreloader,
    paramsInheritanceStrategy: 'always',
    scrollPositionRestoration: 'top',
    relativeLinkResolution: 'corrected'
})],
  exports: [RouterModule],
  providers: [
    AppGuard
  ]
})
export class AppRoutingModule { }
