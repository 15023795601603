import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from '@shared/angular-material';

import { BracelitFormItemModule } from '@bracelit-components/bracelit-form/bracelit-form-item/bracelit-form-item.module';

import { BracelitConfirmDeleteComponent } from './bracelit-confirm-delete.component';

@NgModule({
  declarations: [
    BracelitConfirmDeleteComponent
  ],
    imports: [
        CommonModule,
        MaterialModule,
        ReactiveFormsModule,
        BracelitFormItemModule,
        FlexLayoutModule,
    ],
  exports: [
    BracelitConfirmDeleteComponent
  ]
})
export class BracelitConfirmDeleteModule { }
