import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MaterialModule } from '@shared/angular-material';

import { BracelitFormModule } from '@bracelit-components/bracelit-form';

import { BracelitEmailComponent } from './bracelit-email.component';

@NgModule({
  declarations: [
    BracelitEmailComponent
  ],
  imports: [
    CommonModule,
    BracelitFormModule,
    FlexLayoutModule,
    MaterialModule
  ],
  exports: [
    BracelitEmailComponent
  ]
})
export class BracelitEmailModule { }
