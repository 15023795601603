<div class="image-gallery" (window:keydown)="onKeyDown($event)">
  <!--<h1 mat-dialog-title>{{title}}</h1>-->

  <!--  <div class="image-gallery-container" [ngClass]="{'unique-image': imagePreviews.length === 1}"-->
  <!--       [bracelitBackgroundImage]="imagePreviews[selectedImageIndex]">  -->
  <div class="image-gallery-container" [ngClass]="{'unique-image': imagePreviews.length === 1}">
    <img class="image-preview" [src]="imagePreviews[selectedImageIndex]" alt="Preview">

    <i aria-label="Close" class="close-gallery" (click)="closeDialog()"><!--?xml version="1.0" encoding="UTF-8"?-->
      <svg width="512px" height="512px" enable-background="new 0 0 47.971 47.971" version="1.1"
           viewBox="0 0 47.971 47.971" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"><path d="M28.228,23.986L47.092,5.122c1.172-1.171,1.172-3.071,0-4.242c-1.172-1.172-3.07-1.172-4.242,0L23.986,19.744L5.121,0.88   c-1.172-1.172-3.07-1.172-4.242,0c-1.172,1.171-1.172,3.071,0,4.242l18.865,18.864L0.879,42.85c-1.172,1.171-1.172,3.071,0,4.242   C1.465,47.677,2.233,47.97,3,47.97s1.535-0.293,2.121-0.879l18.865-18.864L42.85,47.091c0.586,0.586,1.354,0.879,2.121,0.879   s1.535-0.293,2.121-0.879c1.172-1.171,1.172-3.071,0-4.242L28.228,23.986z" fill="#fff"></path></svg>
    </i>

    <i aria-label="Previous" class="previous-image-button" (click)="previousImage()"
       *ngIf="imagePreviews.length > 1"
       style="touch-action: pan-y; user-select: none; -webkit-user-drag: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0);">
      <!--?xml version="1.0" encoding="UTF-8"?-->
      <svg width="512px" height="512px" enable-background="new 0 0 240.823 240.823" version="1.1"
           viewBox="0 0 240.823 240.823" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"><path d="m183.19 111.82l-108.3-108.26c-4.752-4.74-12.451-4.74-17.215 0-4.752 4.74-4.752 12.439 0 17.179l99.707 99.671-99.695 99.671c-4.752 4.74-4.752 12.439 0 17.191 4.752 4.74 12.463 4.74 17.215 0l108.3-108.26c4.68-4.691 4.68-12.511-0.012-17.19z" fill="#fff"></path></svg>
    </i>

    <i aria-label="Next" class="next-image-button" (click)="nextImage()" *ngIf="imagePreviews.length > 1"
       style="touch-action: pan-y; user-select: none; -webkit-user-drag: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0);">
      <!--?xml version="1.0" encoding="UTF-8"?-->
      <svg width="512px" height="512px" enable-background="new 0 0 240.823 240.823" version="1.1"
           viewBox="0 0 240.823 240.823" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"><path d="m183.19 111.82l-108.3-108.26c-4.752-4.74-12.451-4.74-17.215 0-4.752 4.74-4.752 12.439 0 17.179l99.707 99.671-99.695 99.671c-4.752 4.74-4.752 12.439 0 17.191 4.752 4.74 12.463 4.74 17.215 0l108.3-108.26c4.68-4.691 4.68-12.511-0.012-17.19z" fill="#fff"></path></svg>
    </i>


    <span *ngIf="imagePreviews.length > 1" class="number-of-images">{{ selectedImageIndex + 1 }}
      / {{ imagePreviews.length }}</span>
  </div>
</div>
