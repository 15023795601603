import { BracelitModel } from '../abstract/bracelit-model';

/**
 * Class ability, determines if an user can/cannot perform an action.
 */
export class Ability extends BracelitModel {
  /** The slug(unique) of the ability. */
  slug!: string;
  /** The name of the ability. */
  name!: string;
  /** The category of the ability. */
  category?: string;
  /** The description of the ability. */
  description?: string;
}
