import { Component, Input, OnInit } from '@angular/core';

import { AuthenticationService } from '@shared/services';
import esJson from '../../../../assets/i18n/es.json';
import enJson from '../../../../assets/i18n/en.json';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input() activeTab: string;
  components: any[] = [];
  options: string[] = [];
  nButtons: number;
  icons: any[] = [];
  actives: any[] = [];
  class: any[] = [];
  public flag = false;
  public hasVouchers: boolean;
  public hasAccessControl: boolean;
  public isTicketScan: boolean;
  public hasPurchases: boolean;
  public hasOnlineOrders: boolean;
  public isTravel: boolean;
  public hasNotifications: boolean;
  public hasLeadScanner: boolean;
  roleUser: any;
  userId: any;
  eventSlug: any;
  langJson: any;

  constructor(public authService: AuthenticationService) {
    this.langJson = (localStorage.getItem('lang') === 'es') ? esJson : enJson;
  }

  ngOnInit(): void {
    this.userId = JSON.parse(localStorage.getItem('userId'));
    this.roleUser = JSON.parse(localStorage.getItem('roleUser'));
    this.eventSlug = JSON.parse(localStorage.getItem('eventSlug'));
    this.hasVouchers = JSON.parse(localStorage.getItem('hasVouchers'));
    this.hasAccessControl = JSON.parse(localStorage.getItem('hasAccessControl'));
    this.isTicketScan = JSON.parse(localStorage.getItem('isTicketScan'));
    this.hasPurchases = JSON.parse(localStorage.getItem('hasPurchases'));
    this.hasOnlineOrders = JSON.parse(localStorage.getItem('hasOnlineOrders'));
    this.isTravel =  JSON.parse(localStorage.getItem('isTravel'));
    this.hasNotifications = JSON.parse(localStorage.getItem('hasNotifications'));
    this.hasLeadScanner = JSON.parse(localStorage.getItem('hasLeadScanner'));
    this.nButtons = 0;
    if (this.roleUser == 'Gestor') {
    this.icons = ['clock-check', 'event'];
    this.options = [this.langJson.footer.dashboard, this.langJson.footer.events];
    this.actives = ['dashboard', 'events'];
    this.components = ['/dashboard', '/eventos'];
    this.nButtons = 2;
    } else if ((this.roleUser === 'Camarero') || (this.roleUser === 'Camarero Exacto')) {
      if (this.hasOnlineOrders) {
        this.icons.push('fastfood');
        this.options.push(this.langJson.footer.orders);
        this.actives.push('orders');
        this.components.push('/pedidos');
      }
      if (this.hasPurchases) {
        this.icons.push('format-list-bulleted-square');
        this.options.push('Productos');
        this.actives.push('products');
        this.components.push('/productos');
      }
      this.nButtons = 2;
    } else if ((this.roleUser === 'Dashboard') || (this.roleUser === 'Accesos')) {
      this.icons = ['clock-check', 'swap-horizontal'];
      this.options = [this.langJson.footer.dashboard, this.langJson.footer.profits];
      this.actives = ['dashboard', 'profits'];
      this.components = ['/dashboard', '/movimientos'];
      this.nButtons = 2;
    } else if (this.roleUser === 'Super Admin' && this.eventSlug !== 'maestro-bracelit') {
      this.icons = ['clock-check', 'swap-horizontal', 'database-search', 'cellphone-nfc'];
      this.options = [this.langJson.footer.dashboard, this.langJson.footer.profits, this.langJson.footer.queries, this.langJson.footer.devices];
      this.actives = ['dashboard', 'profits', 'query','devices'];
      this.components = ['/dashboard', '/movimientos', '/consultas','/dispositivos'];
      this.nButtons += 4;
      if (this.hasPurchases) {
        this.icons.push('format-list-bulleted-square');
        this.options.push(this.langJson.footer.products);
        this.actives.push('products');
        this.components.push('/productos');
        this.nButtons += 1;
      }
      if (this.hasVouchers) {
        this.icons.push('credit-card-check-outline');
        this.options.push(this.langJson.footer.vouchers);
        this.actives.push('vouchers');
        this.components.push('/bonos');
        this.nButtons += 1;
      }
      this.icons.push('account-tie');
      this.icons.push('account');
      this.options.push(this.langJson.footer.workers);
      this.options.push(this.langJson.footer.users);
      this.actives.push('workers');
      this.actives.push('users');
      this.components.push('/trabajadores');
      this.components.push('/usuarios');
      this.nButtons += 2;
      if (this.isTravel){
        this.icons.push('bike-fast');
        this.icons.push('account-group');
        this.options.push(this.langJson.footer.activities);
        this.options.push(this.langJson.footer.groups);
        this.actives.push('activities');
        this.actives.push('groups');
        this.components.push('/actividades');
        this.components.push('/grupos');
        this.nButtons += 2;
      }
      if (this.hasAccessControl || this.isTicketScan) {
        this.icons.push('home-city');
        this.options.push(this.langJson.footer.rooms);
        this.actives.push('rooms');
        this.components.push('/recintos');
        this.nButtons += 1;
      }
      if (this.hasOnlineOrders) {
        this.icons.push('fastfood');
        this.options.push(this.langJson.footer.orders);
        this.actives.push('orders');
        this.components.push('/pedidos');
        this.nButtons += 1;
      }
      if (this.hasNotifications) {
        this.icons.push('notifications_active');
        this.options.push(this.langJson.footer.notifications);
        this.actives.push('notifications');
        this.components.push('/notificaciones');
        this.nButtons += 1;
      }
      if (this.hasLeadScanner) {
        this.icons.push('swap-horizontal');
        this.options.push('LeadScanner');
        this.actives.push('leads');
        this.components.push('/leads');
        this.nButtons += 1;
      }
  
    } else if (this.eventSlug === 'maestro-bracelit') {
      this.icons = ['clock-check', 'database-search', 'database-search', 'account-tie'];
      this.options = [this.langJson.footer.dashboard, this.langJson.footer.queries, this.langJson.footer.create, this.langJson.footer.workers];
      this.actives = ['dashboard', 'query', 'create', 'workers'];
      this.components = ['/dashboard', '/consultas', '/crear', '/trabajadores'];
      this.nButtons += 2;
    } else {
        this.icons = ['clock-check', 'swap-horizontal'];
        this.options = [this.langJson.footer.dashboard, this.langJson.footer.profits];
        this.actives = ['dashboard', 'profits'];
        this.components = ['/dashboard', '/movimientos'];
        this.nButtons += 2;
        if (this.hasPurchases) {
          this.icons.push('format-list-bulleted-square');
          this.options.push(this.langJson.footer.products);
          this.actives.push('products');
          this.components.push('/productos');
          this.nButtons += 1;
        }
        if (this.hasVouchers) {
          this.icons.push('credit-card-check-outline');
          this.options.push(this.langJson.footer.vouchers);
          this.actives.push('vouchers');
          this.components.push('/bonos');
          this.nButtons += 1;
        }
        this.icons.push('account-tie');
        this.icons.push('account');
        this.options.push(this.langJson.footer.workers);
        this.options.push(this.langJson.footer.users);
        this.actives.push('workers');
        this.actives.push('users');
        this.components.push('/trabajadores');
        this.components.push('/usuarios');
        this.nButtons += 2;
        if (this.isTravel){
          this.icons.push('bike-fast');
          this.icons.push('account-group');
          this.options.push(this.langJson.footer.activities);
          this.options.push(this.langJson.footer.groups);
          this.actives.push('activities');
          this.actives.push('groups');
          this.components.push('/actividades');
          this.components.push('/grupos');
          this.nButtons += 2;
        }
        if (this.hasAccessControl || this.isTicketScan) {
          this.icons.push('home-city');
          this.options.push(this.langJson.footer.rooms);
          this.actives.push('rooms');
          this.components.push('/recintos');
          this.nButtons += 1;
        }
        if (this.hasOnlineOrders) {
          this.icons.push('fastfood');
          this.options.push(this.langJson.footer.orders);
          this.actives.push('orders');
          this.components.push('/pedidos');
          this.nButtons += 1;
        }
        if (this.hasNotifications) {
          this.icons.push('notifications_active');
          this.options.push(this.langJson.footer.notifications);
          this.actives.push('notifications');
          this.components.push('/notificaciones');
          this.nButtons += 1;
        }
        if (this.hasLeadScanner) {
          this.icons.push('swap-horizontal');
          this.options.push('LeadScanner');
          this.actives.push('leads');
          this.components.push('/leads');
          this.nButtons += 1;
        }
    
      }
    }
  }

