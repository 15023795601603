import { InjectionToken } from '@angular/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';

/**
 * BRACELIT_FORM_ITEM_DEFAULT_APPEARANCE InjectionToken, provides the default appearance. Default is 'outline'.
 *
 * @example
 *
 * providers: [
 *    {provide: BRACELIT_FORM_ITEM_DEFAULT_APPEARANCE, useValue: 'standard'},
 *    ...
 * ]
 */
export const BRACELIT_FORM_ITEM_DEFAULT_APPEARANCE: InjectionToken<MatFormFieldAppearance> = new InjectionToken<MatFormFieldAppearance>('The default appearance');
