import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { Product } from '@shared/models/product';

@Injectable()
export class ConfEventService {
  public data: any[] = [];
  dataChanged = new Subject<Product[]>();

  setDataEvent(data) {
    this.data = data;
    this.dataChanged.next(this.data);

    return this.data;
  }

  getDataEvent() {
    return this.data;
  }
}
