import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { User } from '../models/user';

@Injectable()
export class AuthenticationService {
  private _token: string;
  private _user: User;
  private _user$: Subject<User> = new Subject<User>();
  private _abilities: string[] = [];
  private _impersonateInformation: any;
  private _impersonateInformation$ = new Subject<any>();
  private _clientId: string;
  private _redirectUrl: string;
  headers: HttpHeaders;
  private protocol: string;
  private server: string;
  private apiUrl: string;
  private subdomain = '';
  private _apiRoute: string;
  private _apiRoute$ = new Subject<string>();
  private _account: string;
  private _event: any;
  private _creditMoves: any;
  loader = true;
  roleUser: any;
  userId: any;
  payOption: string;
  userEmail: any;

  constructor(private router: Router,
              private httpClient: HttpClient) {
    this.protocol = environment.protocol;
    this.server = environment.server;
    this.apiUrl = environment.apiUrl;
    this._apiRoute = this.protocol + this.server + this.apiUrl;

    this.headers = new HttpHeaders();
    this.headers.append('Content-Type', 'application/json');
    this.headers.append('Accept', 'application/json');
    this.headers.append('Authorization', '');

    const currentToken = JSON.parse(localStorage.getItem('token'));
    if (currentToken) {
      this._token = currentToken;
      if (localStorage.getItem('user') && localStorage.getItem('user') !== 'undefined') {
        this._user = new User(JSON.parse(localStorage.getItem('user')));
      }
      if (localStorage.getItem('abilities') && localStorage.getItem('abilities') !== 'undefined') {
        this._abilities = JSON.parse(localStorage.getItem('abilities'));
      }
      if (localStorage.getItem('clientId') && localStorage.getItem('clientId') !== 'undefined') {
        this._clientId = JSON.parse(localStorage.getItem('clientId'));
      }
      if (localStorage.getItem('account') && localStorage.getItem('account') !== 'undefined') {
        this._account = JSON.parse(localStorage.getItem('account'));
      }
      if (localStorage.getItem('Event') && localStorage.getItem('Event') !== 'undefined') {
        this._event = JSON.parse(localStorage.getItem('Event'));
      }
      if (localStorage.getItem('creditMoves') && localStorage.getItem('creditMoves') !== 'undefined') {
        this._creditMoves = JSON.parse(localStorage.getItem('creditMoves'));
      }
    }
  }

  get apiRoute(): string {
    return this._apiRoute;
  }
  get redirectUrl(): string {
    if (this._redirectUrl) {
      return this._redirectUrl;
    } else {
      return '';
    }
  }

  set redirectUrl(value: string) {
    this._redirectUrl = value;
    localStorage.setItem('redirectUrl', JSON.stringify(this._redirectUrl));
  }

  set apiRoute(value: string) {
    this._apiRoute = value;
    this._apiRoute$.next(value);
  }

  get apiRoute$(): Subject<string> {
    return this._apiRoute$;
  }

  get token(): string {
    if (this._token) {
      return this._token;
    } else {
      return '';
    }
  }

  set token(value: string) {
    this._token = value;
    localStorage.setItem('token', JSON.stringify(this.token));
  }

  get CreditMoves(): string {
    if (this._creditMoves) {
      return this._creditMoves;
    } else {
      return '';
    }
  }

  set CreditMoves(value: string) {
    this._creditMoves = value;
    localStorage.setItem('creditMoves', JSON.stringify(this.CreditMoves));
  }

  get Event(): string {
    if (this._event) {
      return this._event;
    } else {
      return '';
    }
  }

  set Event(value: string) {
    this._event = value;
    localStorage.setItem('Event', JSON.stringify(this.Event));
  }

  get account(): string {
    if (this._account) {
      return this._account;
    } else {
      return '';
    }
  }

  set account(value: string) {
    this._account = value;
    localStorage.setItem('account', JSON.stringify(this.account));
  }

  get user(): User {
    return this._user;
  }

  set user(value: User) {
    this._user = value;
    this._user$.next(this._user);
    localStorage.setItem('user', JSON.stringify(this._user));
  }

  get user$(): Subject<User> {
    return this._user$;
  }

  get abilities(): string[] {
    return this._abilities;
  }

  set abilities(data: string[]) {
    this._abilities = data;
    localStorage.setItem('abilities', JSON.stringify(this._abilities));
  }

  isSetAbility(ab: string) {
    if (this.user.role.slug === 'super-admin') {
      return true;
    }

    return this.abilities.includes(ab);
  }

  get impersonateInformation(): any {
    return this._impersonateInformation;
  }

  set impersonateInformation(value: any) {
    this._impersonateInformation = value;
    this._impersonateInformation$.next(value);
    localStorage.setItem('impersonateInformation', JSON.stringify(this._impersonateInformation));
  }

  get impersonateInformation$(): Subject<string> {
    return this._impersonateInformation$;
  }

  get clientId(): string {
    return this._clientId;
  }

  set clientId(value: string) {
    this._clientId = value;
    localStorage.setItem('clientId', JSON.stringify(this._clientId));
  }

  private resetUser() {
    this._token = null;
    this._abilities = null;
    this.user = null;
    this._impersonateInformation = null;
    this._clientId = null;
    this._account = null;
    this._event = null;
    this._creditMoves = null;

    localStorage.removeItem('token');
    localStorage.removeItem('account');
    localStorage.removeItem('Event');
    localStorage.removeItem('creditMoves');
    localStorage.removeItem('abilities');
    localStorage.removeItem('user');
    localStorage.removeItem('pointOfSale');
    localStorage.removeItem('impersonateInformation');
    localStorage.removeItem('clientId');
    localStorage.removeItem('client');
  }

  impersonateAccount(accountId): Observable<boolean> {
    return this.authenticate(this._apiRoute + 'impersonate-account/' + accountId, '', true);
  }

  impersonate(userId): Observable<boolean> {
    return this.authenticate(this._apiRoute + 'impersonate/' + userId, '', true);
  }

  login(data: any): Observable<boolean> {
    if (this.token) {
      this.resetUser();
    }

    return this.authenticate(this._apiRoute + 'login', data);
  }

  private authenticate(route: string, data: any, impersonating: boolean = false): Observable<boolean> {
    return this.httpClient.post(route, data)
      .pipe(
        map((response) => {
          this.setRoleUser(response['user'].role.name);
          this.setUserId(response['user'].id);
          this.setUserEmail(response['user'].email);
          if ((response['user'].role.name === 'Super Admin') || (response['user'].role.name === 'Administrador') || (response['user'].role.name === 'Gestor') || (response['user'].role.name === 'Dashboard') ||  (response['user'].role.name === 'MonitorJefe') ||  (response['user'].role.name === 'Accesos') ||  (response['user'].role.name === 'Camarero') ||  (response['user'].role.name === 'Camarero Exacto')) {
            if (response['token']) {
            if (impersonating) {
              this.impersonateInformation = {
                token: this.token,
                abilities: this.abilities,
                user: this.user,
                account: this.account,
                Event: this.Event,
                CreditMoves: this.CreditMoves
              };
            }
            this.token = 'Bearer ' + response['token'];
            this.account = response['account'];
            if (response['Event']) {
              this.Event = response['Event'][0];
              this.payOption = response['Event'][6];
            }
            if (response['creditMoves']) {
              this.CreditMoves = response['creditMoves'];
            }
            this.abilities = response['abilities'];
            this.user = new User(response['user']);
            if (response['clientId']) {
              this.clientId = response['clientId'];
            }

            return true;
          } else {
            return false;
          }
        } else {
            return false;
        }
      }),
      catchError((error: any) => this.handleError(error))
    );
  }

  setEvent(event: string) {
    this.Event = event;
  }

  undoImpersonate() {
    if (this.impersonateInformation) {
      this.token = this._impersonateInformation.token;
      this.account = this._impersonateInformation.account;
      this.abilities = this._impersonateInformation.abilities;
      this.user = this._impersonateInformation.user;
      this.impersonateInformation = null;
      localStorage.removeItem('impersonateInformation');
    }
  }

  logout(redirectRoute: string = '/'): void {
    this.resetUser();
    this.apiRoute = this.protocol + this.server + this.apiUrl;
    if (redirectRoute) {
      this.router.navigate([redirectRoute]);
    }
  }

  logoutAndStay(): void {
    this.resetUser();
  }

  private handleError(err: HttpErrorResponse | any) {
    if (err.status === 500 && !err.error.error) {
      return throwError('Ha ocurrido un error en el servidor.');
    }

    return throwError(err.error.error);
  }

  getAccountId() {
    return this.account;
  }

  getEventId() {
    return this.Event;
  }

  setLoader(value: boolean) {
    this.loader = value;
  }

  public getLoader() {
    return this.loader;
  }

  setRoleUser(data) {
    this.roleUser = data;
    localStorage.setItem('roleUser', JSON.stringify(this.roleUser));
  }

  getRoleUser() {
    return this.roleUser;
  }

  setUserEmail(data) {
    this.userEmail = data;
    localStorage.setItem('userEmail', JSON.stringify(this.userEmail));
  }

  getUserEmail() {
    return this.userEmail;
  }

  setUserId(data) {
    this.userId = data;
    localStorage.setItem('userId', JSON.stringify(this.userId));
  }

  getUserId() {
    return this.userId;
  }
}
