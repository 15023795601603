<div class="col s12 bracelit-alert {{type}}" [ngClass]="{'hidden': hidden}">
  <span class="close-btn" (click)="closeAlert()">&times;</span>
  <p>
    <i *ngIf="type == 'error'" class="mdi mdi-close"></i>
    <i *ngIf="type == 'warn'" class="mdi mdi-alert"></i>
    <i *ngIf="type == 'success'" class="mdi mdi-check-circle"></i>
    <i *ngIf="type == 'info'" class="mdi mdi-information"></i>
    <ng-content></ng-content>
  </p>
</div>
