import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { ConfEventService } from '@app/confEvent/confEvent.service';

import { Product } from '@shared/models/product';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private users: any[] = [];
  users2: any[];
  private vouchers: any[] = [];
  private vouchersName: any[];
  private i: number;
  public moves: any[] = [];
  public moves2: any[] = [];
  public movesId: any;
  public movesIdCopy: any;
  usersList: any[] = [];
  userArray: any[];
  public dataPush: any[];
  public dataMail: any[];
  usersChanged = new Subject<Product[]>();
  private user: any;
  public usersId: any;
  private l: any;
  public eventInfo: any;
  constructor(private confEventSer: ConfEventService) { }

  setusers(users) {
    this.users = users;
    // this.users = this.transformUsers(this.users);
    this.usersChanged.next(this.users);
    this.userList(this.users);

    return this.users;
  }

  getUsers() {
    return this.users.slice();
  }

  getVouchers() {
    return this.vouchers.slice();
  }
  getEventInfo() {
    this.eventInfo = this.confEventSer.getDataEvent();

    return this.eventInfo;
  }

  setVouchers(vouchers) {
    this.vouchers = vouchers;

    return this.vouchers;
  }

  setNotifyPushResponse(data) {
    this.dataPush = data['PushLeft'];
  }

  setNotifyMailResponse(data) {
    this.dataMail = data['MailLeft'];
  }

  setuserIndex() {
    return this.i;
  }

  transformUsers(users) {
    this.users = users;
    this.usersId = Object.keys(this.users);
    const users2 = [];
    this.l = this.usersId.length;
    for (let i = 0; i < this.l; i++) {
      users2[i] = this.users[this.usersId.shift()];
    }
    this.users = users2;

    return this.users;
  }

  editSetUser(user, i) {
    this.user = user;
    this.i = i;

    return this.user;
  }

  editSetUserEdit(user, i){
    this.user = user;
    this.i = i;
    return this.user
  }

  editGetUser() {
    return this.user;
  }

  parseUserVoucher(user) {
    this.user = user;
    this.vouchersName = [];
    for (let i = 0; i < this.user[0].infoVoucher.length; i++) {
      this.vouchersName.push(this.user.infoVoucher[i].name );
    }
  }

  setMoves(moves) {
    this.moves = moves;
    this.moves = this.transformMoves(this.moves);
  }

  getMoves() {
    return this.moves.slice();
  }

  transformMoves(moves) {
    this.moves = moves;
    if (this.moves.length !== 0) {
      this.movesId = Object.keys(this.moves);
      this.movesIdCopy = this.movesId;
      this.l = this.movesId.length;

      for (let i = 0; i < this.l; i++) {
        this.moves2[i] = this.moves[this.movesId.shift()];
      }
      this.moves = this.moves2;
    }

    return this.moves;
  }

  userList(users) {
    this.users2 = users;
    const l = this.users2.length;
    const usersListCopy = [];
    for (let i = 0; i < l; i++) {
      if (this.users2[i].infoUser && this.users2[i].infoWallet && this.users2[i].infoVoucher) {
        this.userArray = [];
        this.userArray.push({name: this.users2[i].infoUser.name, surname: this.users2[i].infoUser.surname,
        id: this.users2[i].infoUser.id, email: this.users2[i].infoUser.email, phone: this.users2[i].infoUser.phone,
        userId: this.users2[i].infoUser.userId, infoWallet: this.users2[i].infoWallet, infoVoucher: this.users2[i].infoVoucher});
        if (this.users2[i].infoWallet.length > 0) {
          this.userArray.push(this.users2[i].infoWallet[0].money);
        }
        if ((this.users2[i].infoVoucher.length > 0) && (this.users2[i].infoWallet.length > 0)) {
          this.userArray.push(this.users2[i].infoWallet[0].wristbandId);
        }
        usersListCopy[i] = this.userArray;
      }
    }
    this.usersList = usersListCopy;
  }

  userListNew(users) {
    this.users2 = users;
    const l = (Object.keys(users)).length + Number(Object.keys(users)[0]);
    const usersListCopy = [];
    for (let i = Number(Object.keys(users)[0]); i < l; i++) {
      if (this.users2[i].infoUser && this.users2[i].infoWallet && this.users2[i].infoVoucher) {
        this.userArray = [];
        this.userArray.push({name: this.users2[i].infoUser.name, surname: this.users2[i].infoUser.surname,
        id: this.users2[i].infoUser.id, email: this.users2[i].infoUser.email, phone: this.users2[i].infoUser.phone,
        userId: this.users2[i].infoUser.userId, infoWallet: this.users2[i].infoWallet, infoVoucher: this.users2[i].infoVoucher});
        if (this.users2[i].infoWallet.length > 0) {
          this.userArray.push(this.users2[i].infoWallet[0].money);
        }
        if ((this.users2[i].infoVoucher.length > 0) && (this.users2[i].infoWallet.length > 0)) {
          this.userArray.push(this.users2[i].infoWallet[0].wristbandId);
        }
        usersListCopy[i - Number(Object.keys(users)[0])] = this.userArray;
      }
    }
    this.usersList = usersListCopy;
    return this.usersList;
  }
}
