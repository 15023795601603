import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { BracelitBreadcrumbComponent } from './bracelit-breadcrumb.component';
import {FlexLayoutModule} from '@angular/flex-layout';

@NgModule({
  declarations: [
    BracelitBreadcrumbComponent
  ],
    imports: [
        CommonModule,
        RouterModule,
        FlexLayoutModule
    ],
  exports: [
    BracelitBreadcrumbComponent
  ]
})
export class BracelitBreadcrumbModule { }
