<div>
  <h2 mat-dialog-title class="primary-color" fxLayoutAlign="center center">{{tittle}}</h2>
  <mat-icon class="secondary-fill"
            svgIcon="close"
            style="width: 10%; float: right; margin-top: -50px; position: relative; cursor: pointer"
            (click)="onClose()"></mat-icon>
</div>
<div mat-dialog-content>
  <form [formGroup]="myForm" class="login-form-container light-22px-center-white" style="width: 100% !important; display: flex; justify-content: left; flex-direction: column"
  (ngSubmit)="onSubmit()">

    <label [for]="'email'" class="primary-color" style="margin-right: 0px; font-size: 16px; width: 100%; text-align: center; font-weight: bold;">
      Bonos en pulsera </label>
      <div *ngFor="let voucher of actualVouchers; let i = index" class="primary-color" style="padding-top: 10px; font-size: 14px !important; display: flex;
      justify-content: center">
        <div style="display: flex; justify-content:space-between; width: 300px">
          <span style="display: flex; align-items: center;"><strong>{{ 'dataBase.voucher' | translate }}: </strong> {{voucher.name}}</span>
          <!-- <span style="min-width: 110px;"> <strong style="margin-left: 10%;">{{ 'dataBase.uses' | translate }}: </strong> {{voucher.usesLeft}}</span> -->
          <mat-form-field style="text-align: center; width: 125px;">
            <input type="number"
                  min= "0"
                  class="form-control"
                  [formControl]="usesArray.controls[i]"
                  matInput
                  placeholder="Usos Restantes: {{voucher.usesLeft.split('.')[0]}}  ">
            <!-- <mat-hint>Máximo: {{voucherUser[3][i]}}</mat-hint> -->
          </mat-form-field>
        </div>
      </div>

      <label [for]="'email'" class="primary-color" style="margin-top: 40px; font-size: 16px; width: 100%; text-align: center; font-weight: bold;">
        Añadir bonos a pulsera </label>
        <div *ngFor="let voucherNew of eventVouchers; let i = index" class="primary-color" style="padding-top: 10px; font-size: 14px !important; display: flex;
        justify-content: center">
          <div style="display: flex; justify-content:space-between; width: 300px">
            <span style="display: flex; align-items: center;"><strong>{{ 'dataBase.voucher' | translate }}: </strong> {{voucherNew.name}}</span>
            
            <input type="checkbox"
                    class="form-control"
                    [formControl]="usesArrayNew.controls[i]"
                    placeholder="Usos bono: {{voucherNew.uses}}  ">
          </div>
        </div>
  </form>
</div>

<div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10">

  <button class="secondary"
          [class.disabled]="!myForm.valid"
          mat-button
          (click)="onSubmit()">{{saveText}}</button>
</div>
