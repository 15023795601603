import { Injectable } from '@angular/core';

// @Injectable({providedIn: 'root'})
@Injectable()
export class ProfitsService {
  public moves: any[] = [];
  public movesSell: any[] = [];
  public movesRecharge: any[] = [];
  public movesRooms: any[] = [];
  sales_opened = new Map();
  public movesId: any;
  public movesRoomsId: any;
  private amount: any;
  private amountTy: string;
  public movesIdCopy: any;
  public l: any;
  private i: number;
  public move: string;
  public hasErrorMsg = false;

  constructor() {}

  setMoves(moves) {
    this.moves = moves;
  }

  getMoves() {
    return this.moves.slice();
  }

  setMovesSell(moves) {
    this.movesSell = moves;
  }

  getMovesSell() {
    return this.movesSell.slice();
  }

  setMovesRecharge(moves) {
    this.movesRecharge = moves;
  }

  getMovesRecharge() {
    return this.movesRecharge.slice();
  }

  setMovesRooms(moves) {
    this.movesRooms = moves;
    this.movesRooms = this.transformMovesRooms(this.movesRooms);
  }

  getMovesRooms() {
    return this.movesRooms.slice();
  }
  editSetMove(move, i) {
    this.move = move;
    this.i = i;

    return this.move;
  }

  amountType(move) {
    this.amount = move.creditMove.amount;
    if ((this.amount < 0) || (move.creditMove.uses < 0)) {
      this.amountTy = 'Venta';
    } else {
      this.amountTy = 'Recarga';
    }

    return this.amountTy;
  }

  toggleSale(index: number) {
    if (this.sales_opened.has(index)) {
      this.sales_opened.set(index, !this.sales_opened.get(index));
    } else {
      this.sales_opened.set(index, true);
    }
  }

  isSaleHidden(index: number) {
    if (!this.sales_opened.has(index)) {
      return false;
    }

    return this.sales_opened.get(index);
  }

  transformMovesAccount(moves) {
    this.moves = moves;
    const movesId = Object.keys(this.moves);
    const moves2 = [];
    const moves3 = [];
    this.l = movesId.length;
    for (let i = 0; i < this.l; i++) {
      moves2[i] = this.moves[movesId.shift()];
      const movesId2 = Object.keys(moves2[i].data2);
      const ll = movesId2.length;
      for (let ii = 0; ii < ll; ii++) {
        moves3.push(moves2[i].data2[movesId2.shift()]);
      }
    }
    this.moves = moves3;

    return this.moves;
  }

  transformMovesRooms(movesRooms) {
    this.movesRooms = movesRooms;
    this.movesRoomsId = Object.keys(this.movesRooms);
    const movesRooms2 = [];
    this.l = this.movesRoomsId.length;
    for (let i = 0; i < this.l; i++) {
      movesRooms2[i] = this.movesRooms[this.movesRoomsId.shift()];
    }
    this.movesRooms = movesRooms2;

    return this.movesRooms;
  }
}
