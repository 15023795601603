import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DataStorageService } from '@shared/services/data-storage.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService, AuthenticationService, SnackBarService } from '@shared/services';
import esJson from '../../../assets/i18n/es.json';
import enJson from '../../../assets/i18n/en.json';



@Component({
  selector: 'app-associate-form',
  templateUrl: './associate-form.component.html',
  styleUrls: ['./associate-form.component.scss']
})


export class AssociateFormComponent implements OnInit {

  langJson = (localStorage.getItem('lang') === 'es') ? esJson : enJson;
  tittle = (localStorage.getItem('lang') === 'es') ? 'Asociar pulsera a email' : 'Associate wristband to email';
  asociar = (localStorage.getItem('lang') === 'es') ? 'Asociar' : 'Associate';
  minDate: Date;
  maxDate: Date;
  selectedTheme: any;
  uploadForm: any;
  imgURL: any;
  selectedColor: any;
  myForm: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<AssociateFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data_form: any,
    public dataStorageService: DataStorageService,
    private formBuilder: UntypedFormBuilder,
    private snackbarService: SnackBarService,
    private authenticationService: AuthenticationService,
    ) {
      const currentYear = new Date().getFullYear();
      const currentDate = new Date().toString();
      this.minDate = new Date(currentDate);
      this.maxDate = new Date(currentYear + 20, 11, 31);
      this.myForm = formBuilder.group({
        email: ['', [Validators.pattern('^[a-zA-Z0-9.!#$%&’*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$'),
          Validators.required]]
      });

    }

  ngOnInit(): void {
    this.uploadForm = this.formBuilder.group({
      profile: ['']
    });
  }

  onSubmit() {
    this.authenticationService.setLoader(true);
    let wristbandId = this.data_form;
    this.dataStorageService.associateWristbandToEmail(wristbandId, this.myForm.value['email']).subscribe(data => {
      let editResult = data;
      this.authenticationService.setLoader(false);
      this.dialogRef.close(editResult);
      this.snackbarService.openSnackBar('Pulsera asociada correctamente.');
    },
    err => {
      console.log(err.error)
      this.authenticationService.setLoader(false);
      this.snackbarService.openSnackBar(err.error);
      this.dialogRef.close(null);

    });
  }


  onClose(){
    this.dialogRef.close();
  }


}
