import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

/**
 * BracelitParenthesisNumber pipe, transform 0->'-', negative numbers->(number)
 */
@Pipe({
  name: 'bracelitParenthesisNumber'
})
export class BracelitParenthesisNumberPipe implements PipeTransform {

  /**
   * Pipe transform.
   * @param value
   * @param args
   */
  transform(value: any, args?: any): any {
    if (value === 0) {
      return '-';
    } else {
      if (value < 0) {
        value = new DecimalPipe('es').transform(-value, '1.0-0');

        return '(' + (value) + ')';
      } else {
        value = new DecimalPipe('es').transform(value, '1.0-0');

        return value;
      }
    }
  }
}
