<div class="contenedor_global">
    <div class="contenedor_cabecera_completa">
        <div class="contenedor_cabecera">
            <div class="cabecera">
                <p>{{"events.title" | translate}}</p>
            </div>
        </div>

        <div class="contenedor_buscador">
            <button id = "lupa_button" class="contenedor_lupa" (click)="buscador()" style="cursor:pointer;">
                <mat-icon
                    svgIcon="magnify">
                </mat-icon>
            </button>
            <!-- <input id="barra_busqueda" type="text" *ngIf = "hidden" (keypress)="filtro_buscador($event)"> -->
            <input id="barra_busqueda" type="text" *ngIf = "hidden" [(ngModel)]="searchValue" (input) = filtro_buscador()>
            <div id="cerrar_busqueda" class="contenedor_cancel" >
                <button (click)="buscador()" *ngIf = "hidden" style="cursor:pointer;">
                    <mat-icon
                        svgIcon="close">
                    </mat-icon>
                </button>
            </div>
        </div>

        <div class="contenedor_filtros_outside">
            <div class="contenedor_filtros_inside" id = "contenedor_filtros_inside">
                <button id = {{buttons_filter[0].id}} class="button clicked" (click)="activarBoton($event.target)" style="cursor:pointer;">{{buttons_filter[0].contenido}}</button><br>
                <button id = {{buttons_filter[1].id}} class="button" (click)="activarBoton($event.target)" style="cursor:pointer;">{{buttons_filter[1].contenido}}</button>
            </div>
        </div>
    </div>

    <div class="contenedor_add_event">

      <div class="add_event" (click)="abrirFormularioPopup()">
          <button class="icono_plus" style="cursor:pointer;">
              <mat-icon svgIcon="plus-grey"></mat-icon>
          </button>
          <p>{{"events.newEvent" | translate}}</p>
      </div>
      <ng-container *ngIf = "!hidden">
        <div class="evento_anadido" *ngFor="let elemento_evento of data">
            <div class="foto_title" (click)="goToEvent(elemento_evento)" style="cursor:pointer;">
            <mat-icon svgIcon="voucher_use_full"></mat-icon>
            <p>
                {{elemento_evento.name}}
            </p>
            </div>
            <div (click)="abrirFormularioEditPopup(elemento_evento)" style="cursor:pointer;">
              <mat-icon svgIcon="pencil"></mat-icon>
            </div>

            <!-- <div class="precio">
                <p>{{elemento_evento.price | number:'.2-2'}} €</p>
            </div> -->
        </div>
      </ng-container>
      <ng-container *ngIf = "hidden">
        <div class="evento_anadido" *ngFor="let elemento_evento_filtrado of filteredData">
            <div class="foto_title" (click)="goToEvent(elemento_evento_filtrado)" style="cursor:pointer;">
            <mat-icon svgIcon="voucher_use_full"></mat-icon>
            <p>
                {{elemento_evento_filtrado.name}}
            </p>
            </div>
            <div (click)="abrirFormularioEditPopup(elemento_evento_filtrado)" style="cursor:pointer;">
              <mat-icon svgIcon="pencil"></mat-icon>
            </div>

            <!-- <div class="precio">
                <p>{{elemento_evento_filtrado.price | number:'.2-2'}} €</p>
            </div> -->
        </div>
      </ng-container>

    </div>
</div>


<app-footer [activeTab]="'events'"></app-footer>
