import { Directive, ElementRef, Input, OnInit } from '@angular/core';

/**
 * BracelitDecimals directive, limit the number of decimals of an input.
 */
@Directive({
  selector: '[bracelitDecimals]',
})
export class BracelitDecimalsDirective implements OnInit {
  /**
   * Number of decimals.
   * @type {number}
   */
  @Input('bracelitDecimals') decimals: number;
  /**
   * regex.
   */
  private regex: RegExp;
  /**
   * baseRegex.
   */
  private baseRegex: RegExp = /^\d*[.,]?\d{0,2}$/g;
  /**
   * specialKeys.
   */
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-'];

  /**
   * Constructor
   * @param el
   */
  constructor(private el: ElementRef) {
  }

  /**
   * OnInit lifecycle, adds the eventListener and construct the regex to check.
   */
  ngOnInit(): void {
    if (this.decimals !== null) {
      if (this.decimals === 0) {
        // Only allow integers
        this.regex = new RegExp('^[0-9]*$');
      } else {
        if (this.decimals !== 2) {
          this.regex = new RegExp('^\\d*[.,]?\\d{0,' + this.decimals + '}$', 'g');
        } else {
          this.regex = new RegExp(this.baseRegex);
        }
      }
      this.el.nativeElement.addEventListener('keydown', this.onKeyDown.bind(this));
    }
  }

  /**
   * OnKeyDown event.
   * @param event
   */
  onKeyDown(event: KeyboardEvent) {
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1 ||
      window.getSelection().toString().replace(',', '.') === this.el.nativeElement.value.toString()) {
      return;
    }
    const next: string = this.el.nativeElement.value.concat(event.key);
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}
