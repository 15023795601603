import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

/**
 * BracelitDatePipe.
 * If simple, returns dd/MM/yyyy H:mm date.
 * Else transforms the date based on the current date.
 */
@Pipe({
  name: 'bracelitDate'
})
export class BracelitDatePipe implements PipeTransform {

  /**
   * Pipe transform.
   * @param value
   * @param args
   * @param refresh
   */
  transform(value: any, args?: any, refresh?: boolean): any {
    if (args && args === 'simple') {
      return new DatePipe('es').transform(value, 'dd/MM/yyyy H:mm');
    } else {
      const currentDate: moment.Moment = moment();
      const dateToTransform: moment.Moment = moment(value);
      if (dateToTransform > currentDate) {
        return new DatePipe('es').transform(value, 'dd/MM/yyyy H:mm');
      } else {
        const diff = currentDate.diff(dateToTransform, 'minutes');
        const diffInDays = currentDate.startOf('day').diff(dateToTransform.startOf('day'), 'days');
        // Hoy
        if (diff >= 0 && diff < 60) {
          if (diff === 0) {
            return `Hace < 1 minuto`;
          } else if (diff === 0 || diff === 1) {
            return `Hace 1 minuto`;
          } else {
            return `Hace ${diff} minutos`;
          }
        } else if (diff >= 60 && diff <= 1440) {
          if (diff >= 60 && diff <= 120) {
            let transformedDate = `Hace 1 hora`;
            if (diff % 60 > 1) {
              transformedDate = transformedDate + ` y ${diff % 60} minutos`;
            }

            return transformedDate;
          } else {
            const result = Math.round(diff / 60);
            let transformedDate = `Hace ${result} horas`;
            if (diff % 60 > 1) {
              transformedDate = transformedDate + ` y ${diff % 60} minutos`;
            }

            return transformedDate;
          }
        } else if (diff >= 1440 && diff <= 2880 && diffInDays < 2) {
          // Ayer
          const transformedDate = new DatePipe('es').transform(value, 'H:mm');

          return `Ayer ${transformedDate}`;
        } else {
          // Resto de fechas
          return new DatePipe('es').transform(value, 'dd/MM/yyyy H:mm');
        }
      }
    }
  }
}
