<div id="not-found-container">
  <img [src]="notFoundImageUrl" alt="404">

  <div id="not-found-text" fxFlex="100">
    <h2 *ngIf="notFoundText">
      {{notFoundText}}
    </h2>
    <p *ngIf="notFoundAdditionalText">
      {{notFoundAdditionalText}}
    </p>
  </div>

</div>
