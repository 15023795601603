import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { EventModel } from './event.model';

@Injectable({
  providedIn: 'root'
})

export class DataArrayService {
  private dataArray: EventModel = {
    id: '',
    name: '',
    startingDate: '',
    endingDate: '',
    startMoneyReturn: '',
    endMoneyReturn: '',
    returnMoneyCommission: '',
    selectedTheme: '',
    file: '',
    attendees: '',
    isTicketScan: '',
    hasNotifications: '',
    hasVouchers: '',
    hasAccessControl: '',
    isMoneyReturned: '',
    hasRegistration: '',
    hasExactPurchases: '',
    hasPurchases: '',
    hasTpvCash: '',
    hasTpvCard: '',
    hasTpvWristband: '',
    hasOnlyVouchers: '',
    hasQR: '',
    hasPreCharge: '', };
  private dataArraySubject = new BehaviorSubject<EventModel>(this.dataArray);

  dataArray$ = this.dataArraySubject.asObservable();
  data: any;


  addData(data: EventModel) {
    this.dataArray = data;
    this.dataArraySubject.next(this.dataArray);
  }

  getDataArray(): EventModel {
    return this.dataArray;
  }

  setData(value: any){
    this.data = value;
  }

  getData(){
    return this.data;
  }

  event_clicked: EventModel = {
    id: '',
    name: '',
    startingDate: '',
    endingDate: '',
    startMoneyReturn: '',
    endMoneyReturn: '',
    returnMoneyCommission: '',
    selectedTheme: '',
    file: '',
    attendees: '',
    isTicketScan: '',
    hasNotifications: '',
    hasVouchers: '',
    hasAccessControl: '',
    isMoneyReturned: '',
    hasRegistration: '',
    hasExactPurchases: '',
    hasPurchases: '',
    hasTpvCash: '',
    hasTpvCard: '',
    hasTpvWristband: '',
    hasOnlyVouchers: '',
    hasQR: '',
    hasPreCharge: '', };

  setEventClicked(event: EventModel){
    this.event_clicked.id = event.id;
    this.event_clicked.name = event.name;
    this.event_clicked.startingDate = event.startingDate;
    this.event_clicked.endingDate = event.endingDate;
    this.event_clicked.startMoneyReturn = event.startMoneyReturn;
    this.event_clicked.endMoneyReturn = event.endMoneyReturn;
    this.event_clicked.attendees = event.attendees;
    this.event_clicked.hasVouchers = event.hasVouchers;
    this.event_clicked.hasAccessControl = event.hasAccessControl;
    this.event_clicked.returnMoneyCommission = event.returnMoneyCommission;
    this.event_clicked.selectedTheme = event.selectedTheme;
    this.event_clicked.file = event.file;
    this.event_clicked.attendees = event.attendees;
    this.event_clicked.isTicketScan = event.isTicketScan;
    this.event_clicked.isMoneyReturned = event.isMoneyReturned;
    this.event_clicked.hasRegistration = event.hasRegistration;
    this.event_clicked.hasExactPurchases = event.hasExactPurchases;
    this.event_clicked.hasPurchases = event.hasPurchases;
    this.event_clicked.hasTpvCash = event.hasTpvCash;
    this.event_clicked.hasTpvCard = event.hasTpvCard;
    this.event_clicked.hasTpvWristband = event.hasTpvWristband;
    this.event_clicked.hasOnlyVouchers = event.hasOnlyVouchers;
    this.event_clicked.hasQR = event.hasQR;
    this.event_clicked.hasPreCharge = event.hasPreCharge;

  }

  getEventClicked(){
    return this.event_clicked;
  }


  delete_event_state = "";


  setDeleteEventState(flag: boolean){
    if (flag === true){
      this.delete_event_state = "borrar_evento";
    } else {
      this.delete_event_state = "actualizar_evento";
    }

  }

}
