<!-- Table mode -->
<mat-card *ngIf="displayMode == 'table'" class="table-mode-container">
  <mat-card-title *ngIf="title" class="table-mode-title" [ngClass]="{'title-with-icon': titleIcon}">
    <mat-icon *ngIf="titleIcon" [svgIcon]="titleIcon"></mat-icon>
    <span class="title">{{ title }}</span>
    <span *ngIf="helpTooltip" class="help-section">
        <mat-icon [svgIcon]="helpIcon" matTooltip="{{helpTooltip}}" matTooltipPosition="above"></mat-icon>
    </span>
  </mat-card-title>
  <mat-card-content class="model-files-content border-bottom" fxLayout="row wrap">
    <table class="model-files-table" [ngClass]="{'model-files-table-with-preview' : preview}">
      <colgroup>
        <col span="1" class="col-1-width">
        <col span="1" class="col-2-width">
        <col span="1" class="col-3-width">
        <col span="1" class="col-4-width">
      </colgroup>
      <tbody>

      <tr class="upload-item-content model-files-border-bottom"
          *ngFor="let myFile of myFiles; let i = index;">
        <td>
                <span *ngIf="!preview" [ngSwitch]="myFile.extension">
                  <mat-icon *ngSwitchCase="'pdf'" svgIcon="icon-doc-pdf"></mat-icon>
                  <mat-icon *ngSwitchCase="'xls'" svgIcon="icon-doc-xls"></mat-icon>
                  <mat-icon *ngSwitchCase="'xlsx'" svgIcon="icon-doc-xlsx"></mat-icon>
                  <mat-icon *ngSwitchCase="'csv'" svgIcon="icon-doc-csv"></mat-icon>
                  <mat-icon *ngSwitchCase="'jpg'" svgIcon="icon-doc-img"></mat-icon>
                  <mat-icon *ngSwitchCase="'jpeg'" svgIcon="icon-doc-img"></mat-icon>
                  <mat-icon *ngSwitchCase="'gif'" svgIcon="icon-doc-img"></mat-icon>
                  <mat-icon *ngSwitchCase="'png'" svgIcon="icon-doc-img"></mat-icon>
                  <mat-icon *ngSwitchCase="'svg'" svgIcon="icon-doc-img"></mat-icon>
                  <mat-icon *ngSwitchDefault svgIcon="icon-doc-gen"></mat-icon>
                </span>
          <div *ngIf="preview" [bracelitBackgroundImage]="imagePreviews[i]" class="image-container"
               (click)="openImagePreview(i)">
                             <span class="preview-icon">
                                <mat-icon svgIcon="image-search"></mat-icon>
                            </span>
          </div>
        </td>
        <td>
          <span class="file-name" (click)="downloadFile(myFile)">{{ myFile.name }}</span>
        </td>
        <td *ngIf="uploadEnabled">
                <span style="max-height: 45px">
                        <svg version="1.1"
                             xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 130.2 130.2">
                            <circle class="path circle" fill="none" stroke="#359964" stroke-width="6"
                                    stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
                            <polyline class="path check" fill="none" stroke="#359964" stroke-width="6"
                                      stroke-linecap="round" stroke-miterlimit="10"
                                      points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
                        </svg>
                        </span>
        </td>
        <td *ngIf="uploadEnabled" class="action-icon" matTooltip="Eliminar" matTooltipPosition="above"
            (click)="deleteFile(myFile)">
          <mat-icon svgIcon="delete"></mat-icon>
        </td>
      </tr>

      <ng-container *ngIf="uploadEnabled">
        <tr class="upload-item-content model-files-border-bottom"
            *ngFor="let f of files; let i = index;">
          <td>
                      <span *ngIf="!preview" [ngSwitch]="f.type">
                        <mat-icon *ngSwitchCase="'application/pdf'" svgIcon="icon-doc-pdf"></mat-icon>
                        <mat-icon *ngSwitchCase="'application/vnd.ms-excel'" svgIcon="icon-doc-xls"></mat-icon>
                        <mat-icon *ngSwitchCase="'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"
                                  svgIcon="icon-doc-xlsx"></mat-icon>
                        <mat-icon *ngSwitchCase="'text/csv'" svgIcon="icon-doc-csv"></mat-icon>
                        <mat-icon *ngSwitchCase="'image/jpeg'" svgIcon="icon-doc-img"></mat-icon>
                        <mat-icon *ngSwitchCase="'image/jpg'" svgIcon="icon-doc-img"></mat-icon>
                        <mat-icon *ngSwitchCase="'image/gif'" svgIcon="icon-doc-img"></mat-icon>
                        <mat-icon *ngSwitchCase="'image/png'" svgIcon="icon-doc-img"></mat-icon>
                        <mat-icon *ngSwitchCase="'image/svg+xml'" svgIcon="icon-doc-img"></mat-icon>
                        <mat-icon *ngSwitchDefault svgIcon="icon-doc-gen"></mat-icon>
                      </span>
            <div *ngIf="preview" [bracelitBackgroundImage]="imagePreviews[i+ myFiles.length]" class="image-container"
                 (click)="openImagePreview(i+ myFiles.length)">
                               <span class="preview-icon">
                                  <mat-icon svgIcon="image-search"></mat-icon>
                              </span>
            </div>
          </td>
          <td>
            <span class="file-name" (click)="downloadFile(newFiles[i])">{{ newFiles[i]?.name }}</span>
          </td>
          <td>
            <mat-progress-bar mode="determinate" value="{{f?.progress?.data?.percentage}}"
                              *ngIf='f?.progress?.data?.percentage!=100 || !uploadedFiles[i]'></mat-progress-bar>
            <span *ngIf=' f?.progress?.data?.percentage>0' style="max-height: 45px">
                        <svg *ngIf='f?.progress?.data?.percentage==100 && uploadedFiles[i]' version="1.1"
                             xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 130.2 130.2">
                            <circle class="path circle" fill="none" stroke="#359964" stroke-width="6"
                                    stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
                            <polyline class="path check" fill="none" stroke="#359964" stroke-width="6"
                                      stroke-linecap="round" stroke-miterlimit="10"
                                      points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
                        </svg>
                        <svg *ngIf="f?.progress?.data?.percentage<100 && !uploadedFiles[i]" version="1.1"
                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                            <circle class="path circle" fill="none" stroke="#D06079" stroke-width="6"
                                    stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
                            <line class="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round"
                                  stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3"/>
                            <line class="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round"
                                  stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2"/>
                        </svg>
                        </span>
          </td>
          <td class="action-icon" matTooltip="Eliminar" matTooltipPosition="above"
              (click)="deleteNewFile(newFiles[i], f)">
            <mat-icon svgIcon="delete"></mat-icon>
          </td>
        </tr>
        <!--archivos rechazados-->
        <tr *ngIf="rejectedFiles.length!=0" class="upload-item-content model-files-border-bottom">
          <td colspan="2">Archivos no subidos:</td>
          <td></td>
          <!--<td style="font-size: small">(formatos válidos: {{allowedFileFormats}})</td>-->
          <td class="action-icon" matTooltip="Eliminar todos" matTooltipPosition="above"
              (click)="removeAllFilesNoLoaded()">
            <mat-icon svgIcon="delete"></mat-icon>
          </td>
        </tr>
        <tr class="upload-item-content model-files-border-bottom"
            *ngFor="let f of rejectedFiles; let i = index;">
          <td *ngIf="!preview">
                  <span [ngSwitch]="f.type">
                        <mat-icon *ngSwitchCase="'application/pdf'" svgIcon="icon-doc-pdf"></mat-icon>
                        <mat-icon *ngSwitchCase="'application/vnd.ms-excel'" svgIcon="icon-doc-xls"></mat-icon>
                        <mat-icon *ngSwitchCase="'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"
                                  svgIcon="icon-doc-xlsx"></mat-icon>
                        <mat-icon *ngSwitchCase="'text/csv'" svgIcon="icon-doc-csv"></mat-icon>
                        <mat-icon *ngSwitchCase="'image/jpeg'" svgIcon="icon-doc-img"></mat-icon>
                        <mat-icon *ngSwitchCase="'image/jpg'" svgIcon="icon-doc-img"></mat-icon>
                        <mat-icon *ngSwitchCase="'image/gif'" svgIcon="icon-doc-img"></mat-icon>
                        <mat-icon *ngSwitchCase="'image/png'" svgIcon="icon-doc-img"></mat-icon>
                        <mat-icon *ngSwitchCase="'image/svg+xml'" svgIcon="icon-doc-img"></mat-icon>
                        <mat-icon *ngSwitchDefault svgIcon="icon-doc-gen"></mat-icon>
                      </span>
          </td>
          <td *ngIf="!preview" class="filename">
            <span>{{ f.name }} </span>
          </td>
          <td *ngIf="preview" colspan="2">
            <span><b>{{ i + 1 }}.</b></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>{{ f.name }}</span>
          </td>
          <td>
                    <span style="max-height: 45px">
                        <svg version="1.1"
                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                            <circle class="path circle" fill="none" stroke="#D06079" stroke-width="6"
                                    stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
                            <line class="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round"
                                  stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3"/>
                            <line class="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round"
                                  stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2"/>
                        </svg>
                        </span>
          </td>
          <td class="action-icon" matTooltip="Eliminar" matTooltipPosition="above"
              (click)="removeFileNoLoaded(i)">
            <mat-icon svgIcon="delete"></mat-icon>
          </td>
        </tr>
      </ng-container>
      </tbody>
    </table>

    <div *ngIf="uploadEnabled" class="table-mode-actions">
      <!--
      <label fxFlex="calc(35%-12px)" fxFlex.lt-md="100" ngFileDrop [options]="options"
             (uploadOutput)="checkOutput($event)" [uploadInput]="uploadInput"
             class="table-mode-button" [attr.for]="id">
        <mat-icon svgIcon="plus"></mat-icon>
        <span class="plus-input-text">{{ inputText }}</span>
      </label>
      -->
      <label class="table-mode-drop-box" ngFileDrop [options]="options"
             (uploadOutput)="checkOutput($event)" [uploadInput]="uploadInput" [attr.for]="id">
        <mat-icon svgIcon="paperclip"></mat-icon>
        {{ dropBoxText }}
      </label>

      <input [id]="id" style="display: none" type="file" ngFileSelect [options]="options"
             (uploadOutput)="checkOutput($event)" [uploadInput]="uploadInput" onclick="this.value = null"
             multiple>
    </div>
    <div *ngIf="recommendedDimensions" class="dimensions-tip" fxFlex="100">
      {{ recommendedDimensions }}
    </div>
    <div *ngIf="maxFileSizeTip" class="dimensions-tip" fxFlex="100">
      {{ maxFileSizeTip }}
    </div>
  </mat-card-content>
</mat-card>

<!-- Circular mode (1 file) -->
<div *ngIf="displayMode == 'circle'" class="circular-mode-container">
  <div class="circular-mode-image-container">
    <label *ngIf="myFiles.length == 0 && newFiles.length == 0"
           class="circular-mode-image-circle"
           [bracelitBackgroundImage]="placeholderImageUrl"
           [attr.for]="id">
             <span class="preview-icon">
                        <mat-icon svgIcon="pencil"></mat-icon>
             </span>
    </label>
    <div *ngIf="myFiles.length == 1 || newFiles.length == 1" class="circular-mode-image-circle"
         [bracelitBackgroundImage]="imagePreviews[0]" (click)="openImagePreview(0)">
                 <span class="preview-icon">
                        <mat-icon svgIcon="image-search"></mat-icon>
                 </span>
    </div>
  </div>
  <div class="circular-mode-actions-container">
    <label *ngIf="selectImageLabel" class="circular-mode-actions" [attr.for]="id">
      <mat-icon svgIcon="pencil"></mat-icon>
      <span *ngIf="myFiles.length == 0 && newFiles.length == 0">{{ selectImageLabel }}</span>
      <span *ngIf="myFiles.length == 1 || newFiles.length == 1">{{ inputText }}</span>
    </label>
    <label *ngIf="!selectImageLabel" class="circular-mode-actions-absolute" [attr.for]="id">
      <mat-icon svgIcon="camera"></mat-icon>
    </label>

    <input *ngIf="cropEnabled" [id]="id" style="display: none" type="file"
           (change)="openCrop($event)"
           onclick="this.value = null"/>

    <input *ngIf="!cropEnabled" [id]="id" style="display: none" type="file" ngFileSelect [options]="options"
           (uploadOutput)="onResetFiles($event)" [uploadInput]="uploadInput" onclick="this.value = null"
           multiple>
  </div>
</div>

<!-- Rectangular mode (1 file) -->
<div *ngIf="displayMode == 'square'" class="square-mode-container">
  <div class="square-mode-title" *ngIf="title">
    {{ title }}
    <span *ngIf="helpTooltip" class="help-section">
        <mat-icon [svgIcon]="helpIcon" matTooltip="{{helpTooltip}}" matTooltipPosition="above"></mat-icon>
    </span>
  </div>

  <label *ngIf="myFiles.length == 0 && newFiles.length == 0 && !uploadingFile" [attr.for]="id"
         class="square-mode-button-container">
    <mat-icon [svgIcon]="buttonIcon"></mat-icon>
    {{ inputText }}
  </label>

  <div *ngIf="preview && (myFiles.length == 1 || newFiles.length == 1)" [bracelitBackgroundImage]="imagePreviews[0]"
       class="square-mode-image-container"
       [style.height.px]="160/aspectRatio"
       (click)="openImagePreview(0)">
      <span class="preview-icon">
          <mat-icon svgIcon="image-search"></mat-icon>
      </span>
  </div>

  <div *ngIf="!preview && (myFiles.length == 1 || newFiles.length == 1)">
        <span *ngIf="newFiles.length == 1">
            {{ newFiles[0].name }}
        </span>
    <span *ngIf="myFiles.length == 1">
            {{ myFiles[0].name }}
        </span>
  </div>

  <div class="square-mode-actions-container">
    <label class="square-mode-edit-action" [attr.for]="id">
      <!--<mat-icon svgIcon="pencil" *ngIf="imagePreviews.length == 0"></mat-icon>-->
      <span
          *ngIf="noFilesLabelEnabled && myFiles.length == 0 && newFiles.length == 0 && !uploadingFile">{{ noFilesLabel }}</span>
      <span *ngIf="myFiles.length == 1 || newFiles.length == 1 || uploadingFile">
        <mat-icon svgIcon="pencil"></mat-icon>
        {{ editButtonText }}
      </span>
    </label>

    <div *ngIf="newFiles.length == 1" class="square-mode-delete-action"
         (click)="deleteNewFile(newFiles[0], files[0])">
      <mat-icon svgIcon="delete"></mat-icon>
      {{ deleteButtonText }}
    </div>
    <div *ngIf="myFiles.length == 1" class="square-mode-delete-action" (click)="deleteFile(myFiles[0])">
      <mat-icon svgIcon="delete"></mat-icon>
      {{ deleteButtonText }}
    </div>

    <input *ngIf="cropEnabled" [id]="id" style="display: none" type="file"
           (change)="openCrop($event)"
           onclick="this.value = null"/>

    <input *ngIf="!cropEnabled" [id]="id" style="display: none" type="file" ngFileSelect [options]="options"
           (uploadOutput)="onResetFiles($event)" [uploadInput]="uploadInput" onclick="this.value = null"
           multiple>
  </div>

  <div *ngIf="showSize && newFiles.length == 1" class="dimensions-tip" fxFlex="100">
    {{ newFiles[0].size / 1000000 | number: '1.2-2' }} MB
    <span *ngIf="showPages">{{ newFiles[0].pages }}páginas</span>
  </div>

  <div *ngIf="recommendedDimensions" class="dimensions-tip" fxFlex="100">
    {{ recommendedDimensions }}
  </div>
  <div *ngIf="maxFileSizeTip" class="dimensions-tip" fxFlex="100">
    {{ maxFileSizeTip }}
  </div>
  <bracelit-preloader *ngIf="uploadingFile" [diameter]="20" [loadingText]="uploadingFileText">
  </bracelit-preloader>
</div>

<!-- Compound mode (1 file) -->
<div *ngIf="displayMode == 'compound'" class="compound-mode-container">
  <div class="compound-mode-title">
    <span *ngIf="title">{{ title }}</span>
    <span *ngIf="helpTooltip" class="help-section">
        <mat-icon [svgIcon]="helpIcon" matTooltip="{{helpTooltip}}" matTooltipPosition="above"></mat-icon>
    </span>
  </div>

  <label *ngIf="myFiles.length == 0 && newFiles.length == 0 && !uploadingFile" [attr.for]="id"
         class="compound-mode-button-container">
    <mat-icon svgIcon="image-plus"></mat-icon>
    {{ inputText }}
  </label>

  <div *ngIf="preview && (myFiles.length == 1 || newFiles.length == 1)" [bracelitBackgroundImage]="imagePreviews[0]"
       class="compound-mode-image-container"
       (click)="openImagePreview(0)">
      <span class="preview-icon">
          <mat-icon svgIcon="image-search"></mat-icon>
      </span>
  </div>

  <div *ngIf="!preview && (myFiles.length == 1 || newFiles.length == 1)">
        <span *ngIf="newFiles.length == 1">
            {{ newFiles[0].name }}
        </span>
    <span *ngIf="myFiles.length == 1">
            {{ myFiles[0].name }}
        </span>
  </div>
  <div *ngIf="uploadEnabled && myFiles.length == 0 && newFiles.length == 0 && !uploadingFile"
       class="compound-mode-drop-box-separator">- o -
  </div>

  <div *ngIf="uploadEnabled" class="compound-mode-actions">
    <label class="compound-mode-drop-box" ngFileDrop [options]="options"
           (uploadOutput)="checkOutput($event)" [uploadInput]="uploadInput" [attr.for]="id">
      <ng-container *ngIf="myFiles.length == 0 && newFiles.length == 0 && !uploadingFile">
        <div class="compound-mode-drop-box-box">
          <mat-icon *ngIf="dropBoxIconEnabled" svgIcon="paperclip"></mat-icon>
          {{ dropBoxText }}
        </div>
      </ng-container>
    </label>

    <input *ngIf="cropEnabled" [id]="id" style="display: none" type="file"
           (change)="openCrop($event)"
           onclick="this.value = null"/>

    <input *ngIf="!cropEnabled" [id]="id" style="display: none" type="file" ngFileSelect [options]="options"
           (uploadOutput)="onResetFiles($event)" [uploadInput]="uploadInput" onclick="this.value = null"
           multiple>
  </div>

  <div class="compound-mode-actions-container"
       [ngClass]="{'full-width': myFiles.length == 0 && newFiles.length == 0 && !uploadingFile}">
    <label class="compound-mode-edit-action" [attr.for]="id">
      <!--<mat-icon svgIcon="pencil" *ngIf="imagePreviews.length == 0"></mat-icon>-->
      <span class="no-files-text-container"
            *ngIf="noFilesLabelEnabled && myFiles.length == 0 && newFiles.length == 0 && !uploadingFile">
        {{ noFilesLabel }}
      </span>
      <span *ngIf="myFiles.length == 1 || newFiles.length == 1 || uploadingFile">
        <mat-icon svgIcon="pencil"></mat-icon>
        {{ editButtonText }}
      </span>
    </label>

    <div *ngIf="newFiles.length == 1" class="compound-mode-delete-action"
         (click)="deleteNewFile(newFiles[0], files[0])">
      <mat-icon svgIcon="delete"></mat-icon>
      {{ deleteButtonText }}
    </div>
    <div *ngIf="myFiles.length == 1" class="compound-mode-delete-action" (click)="deleteFile(myFiles[0])">
      <mat-icon svgIcon="delete"></mat-icon>
      {{ deleteButtonText }}
    </div>
  </div>

  <div *ngIf="recommendedDimensions" class="dimensions-tip" fxFlex="100">
    {{ recommendedDimensions }}
  </div>
  <div *ngIf="maxFileSizeTip" class="dimensions-tip" fxFlex="100">
    {{ maxFileSizeTip }}
  </div>
  <bracelit-preloader *ngIf="uploadingFile" [diameter]="20" [loadingText]="uploadingFileText">
  </bracelit-preloader>
</div>

<!-- Mobile mode (1 file) -->
<div *ngIf="displayMode == 'mobile'" class="mobile-mode-container">
  <div class="mobile-mode-title" *ngIf="title">
    {{ title }}
    <span *ngIf="helpTooltip" class="help-section">
        <mat-icon [svgIcon]="helpIcon" matTooltip="{{helpTooltip}}" matTooltipPosition="above"></mat-icon>
    </span>
  </div>

  <label *ngIf="myFiles.length == 0 && newFiles.length == 0 && !uploadingFile" [attr.for]="id"
         class="mobile-mode-button-container">
    <mat-icon [svgIcon]="buttonIcon"></mat-icon>
    {{ inputText }}
  </label>

  <div *ngIf="preview && (myFiles.length == 1 || newFiles.length == 1)" [bracelitBackgroundImage]="imagePreviews[0]"
       class="mobile-mode-image-container"
       (click)="openImagePreview(0)">
      <span class="preview-icon">
          <mat-icon svgIcon="image-search"></mat-icon>
      </span>
  </div>

  <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">

    <div *ngIf="fileTypeIcon && (myFiles.length == 1 || newFiles.length == 1)" fxFlex="40px">
      <span *ngIf="myFiles.length == 1" [ngSwitch]="myFiles[0].extension">
        <mat-icon *ngSwitchCase="'pdf'" svgIcon="icon-doc-pdf"></mat-icon>
        <mat-icon *ngSwitchCase="'xls'" svgIcon="icon-doc-xls"></mat-icon>
        <mat-icon *ngSwitchCase="'xlsx'" svgIcon="icon-doc-xlsx"></mat-icon>
        <mat-icon *ngSwitchCase="'csv'" svgIcon="icon-doc-csv"></mat-icon>
        <mat-icon *ngSwitchCase="'jpg'" svgIcon="icon-doc-img"></mat-icon>
        <mat-icon *ngSwitchCase="'jpeg'" svgIcon="icon-doc-img"></mat-icon>
        <mat-icon *ngSwitchCase="'gif'" svgIcon="icon-doc-img"></mat-icon>
        <mat-icon *ngSwitchCase="'png'" svgIcon="icon-doc-img"></mat-icon>
        <mat-icon *ngSwitchCase="'svg'" svgIcon="icon-doc-img"></mat-icon>
        <mat-icon *ngSwitchCase="'docx'" svgIcon="icon-doc-word"></mat-icon>
        <mat-icon *ngSwitchCase="'odt'" svgIcon="icon-doc-odt"></mat-icon>
        <mat-icon *ngSwitchDefault svgIcon="icon-doc-gen"></mat-icon>
      </span>

      <span *ngIf="newFiles.length == 1" [ngSwitch]="newFiles[0].extension">
        <mat-icon *ngSwitchCase="'pdf'" svgIcon="icon-doc-pdf"></mat-icon>
        <mat-icon *ngSwitchCase="'xls'" svgIcon="icon-doc-xls"></mat-icon>
        <mat-icon *ngSwitchCase="'xlsx'" svgIcon="icon-doc-xlsx"></mat-icon>
        <mat-icon *ngSwitchCase="'csv'" svgIcon="icon-doc-csv"></mat-icon>
        <mat-icon *ngSwitchCase="'jpg'" svgIcon="icon-doc-img"></mat-icon>
        <mat-icon *ngSwitchCase="'jpeg'" svgIcon="icon-doc-img"></mat-icon>
        <mat-icon *ngSwitchCase="'gif'" svgIcon="icon-doc-img"></mat-icon>
        <mat-icon *ngSwitchCase="'png'" svgIcon="icon-doc-img"></mat-icon>
        <mat-icon *ngSwitchCase="'svg'" svgIcon="icon-doc-img"></mat-icon>
        <mat-icon *ngSwitchCase="'docx'" svgIcon="icon-doc-word"></mat-icon>
        <mat-icon *ngSwitchCase="'odt'" svgIcon="icon-doc-odt"></mat-icon>
        <mat-icon *ngSwitchDefault svgIcon="icon-doc-gen"></mat-icon>
      </span>
    </div>

    <div fxFlex="calc(100% - 80px)" fxLayout="row wrap" fxLayoutAlign="center center">
      <div *ngIf="!preview && (myFiles.length == 1 || newFiles.length == 1)" fxFlex="100">
        <span *ngIf="newFiles.length == 1" class="name-container">
            {{ newFiles[0].name }}
        </span>
        <span *ngIf="myFiles.length == 1" class="name-container">
            {{ myFiles[0].name }}
        </span>
      </div>

      <div *ngIf="showSize && newFiles.length == 1" class="dimensions-tip" fxFlex="100">
        <span *ngIf="newFiles[0].size >= 10000">{{ newFiles[0].size / 1000000 | number: '1.2-2' }} MB</span>
        <span *ngIf="newFiles[0].size < 10000">{{ newFiles[0].size / 10000 | number: '1.2-2' }} KB</span>
        <span *ngIf="showPages">&nbsp;&middot;{{ newFiles[0].pages }} páginas</span>
      </div>

      <div *ngIf="showSize && myFiles.length == 1" class="dimensions-tip" fxFlex="100">
        <span *ngIf="myFiles[0].size >= 10000">{{ myFiles[0].size / 1000000 | number: '1.2-2' }} MB</span>
        <span *ngIf="myFiles[0].size < 10000">{{ myFiles[0].size / 10000 | number: '1.2-2' }} KB</span>
        <span *ngIf="showPages">&nbsp;&middot;{{ myFiles[0].pages }} páginas</span>
      </div>

      <div *ngIf="recommendedDimensions" class="dimensions-tip" fxFlex="100">
        {{ recommendedDimensions }}
      </div>
      <div *ngIf="maxFileSizeTip" class="dimensions-tip" fxFlex="100">
        {{ maxFileSizeTip }}
      </div>
      <bracelit-preloader *ngIf="uploadingFile" [diameter]="20" [loadingText]="uploadingFileText">
      </bracelit-preloader>
    </div>

    <!--archivos rechazados-->
    <div *ngIf="rejectedFiles.length!=0 && myFiles.length == 0 && newFiles.length == 0" fxFlex="100">
        <span class="mobile-rejected-files-tip">
          {{rejectedFileError}}
        </span>
    </div>

    <div class="mobile-mode-actions-container" fxFlex="40px">
      <label class="mobile-mode-edit-action" [attr.for]="id">
        <!--<mat-icon svgIcon="pencil" *ngIf="imagePreviews.length == 0"></mat-icon>-->
        <span *ngIf="noFilesLabelEnabled && myFiles.length == 0 && newFiles.length == 0 && !uploadingFile">
        {{ noFilesLabel }}
      </span>
        <!--<span *ngIf="myFiles.length == 1 || newFiles.length == 1 || uploadingFile">-->
        <!--<mat-icon svgIcon="pencil"></mat-icon>-->
        <!--{{editButtonText}}-->
        <!--</span>-->
      </label>

      <div *ngIf="newFiles.length == 1 && deleteEnabled" class="mobile-mode-delete-action"
           (click)="deleteNewFile(newFiles[0], files[0])">
        <mat-icon svgIcon="window-close"></mat-icon>
        <!--{{deleteButtonText}}-->
      </div>
      <div *ngIf="myFiles.length == 1 && deleteEnabled" class="mobile-mode-delete-action"
           (click)="deleteFile(myFiles[0])">
        <mat-icon svgIcon="window-close"></mat-icon>
        <!--{{deleteButtonText}}-->
      </div>

      <input *ngIf="cropEnabled" [id]="id" style="display: none" type="file"
             (change)="openCrop($event)"
             onclick="this.value = null"/>

      <input *ngIf="!cropEnabled" [id]="id" style="display: none" type="file" ngFileSelect [options]="options"
             (uploadOutput)="onResetFiles($event)" [uploadInput]="uploadInput" onclick="this.value = null"
             multiple>
    </div>
  </div>
</div>
