<form #f="ngForm">



  <div>
    <div class="cabecera">
        <p>{{"events.formTitle" | translate}}</p>
    </div>
  </div>


  <div class="contenedor_formulario">

    <div class="campo_formulario">
      <p>
        <label>{{"events.formName" | translate}}</label>
      </p>

        <mat-form-field class="mat-form-field_personalizado" appearance="outline">
          <!-- <mat-label>Nombre del evento</mat-label> -->
          <input matInput placeholder="{{'events.formNameLegend' | translate}}" type="text" [(ngModel)]="nuevoItem.name" name="nombre" required nombre #nombreCtrl="ngModel">
          <mat-error *ngIf="name_control.invalid">{{getErrorMessage_name()}}</mat-error>
          <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
          <mat-hint>Hint</mat-hint> -->
        </mat-form-field>

    </div>

    <div class="campo_formulario">
      <p>
        <label>{{"events.formDates" | translate}}</label>
      </p>

      <mat-form-field appearance="outline">
        <mat-label>{{'events.formDatesLegend' | translate}}</mat-label>
        <!-- <mat-date-range-input [rangePicker]="picker" [min]="minDate" [max]="maxDate"> -->
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate placeholder="{{'events.formDatesLegendStart' | translate}}"  [(ngModel)]="nuevoItem.startingDate" name="startingDate" required>
          <input matEndDate placeholder="{{'events.formDatesLegendEnd' | translate}}" [(ngModel)]="nuevoItem.endingDate" name="endingDate" required>
        </mat-date-range-input>
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
        <mat-hint>DD/MM/YYYY – DD/MM/YYYY</mat-hint>

        <mat-error *ngIf="date_control.invalid">{{getErrorMessage_init_date()}}</mat-error>
      </mat-form-field>

    </div>

    <div class="campo_formulario">
      <p>
        <label>{{"events.formAttendees" | translate}}</label>
      </p>
      <mat-form-field class="mat-form-field_personalizado" appearance="outline">
        <input matInput placeholder="{{'events.formAttendeesLegend' | translate}}" type="number" min="0" [(ngModel)]="nuevoItem.attendees" name="attendees" required precio #attendeesCtrl="ngModel">
        <mat-error *ngIf="attendees_control.invalid">{{getErrorMessage_attendees()}}</mat-error>
      </mat-form-field>
    </div>

    <div class="campo_formulario">
      <p>
        <label>{{"events.formDatesReturn" | translate}}</label>
      </p>
      <mat-form-field appearance="outline">
        <mat-label>{{'events.formDatesLegendReturn' | translate}}</mat-label>
        <!-- <mat-date-range-input [rangePicker]="picker" [min]="minDate" [max]="maxDate"> -->
        <mat-date-range-input [rangePicker]="picker2">
          <input matStartDate placeholder="{{'events.formDatesLegendStartReturn' | translate}}"  [(ngModel)]="nuevoItem.startMoneyReturn" name="startMoneyReturn" required>
          <input matEndDate placeholder="{{'events.formDatesLegendEndReturn' | translate}}" [(ngModel)]="nuevoItem.endMoneyReturn" name="endMoneyReturn" required>
        </mat-date-range-input>
        <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
        <mat-date-range-picker #picker2></mat-date-range-picker>
        <mat-hint>DD/MM/YYYY – DD/MM/YYYY</mat-hint>
        <mat-error *ngIf="return_control.invalid">{{getErrorMessage_init_date()}}</mat-error>
      </mat-form-field>
    </div>
    <div class="campo_formulario">
      <p>
        <label>{{"events.formCommision" | translate}}</label>
      </p>
        <mat-form-field class="mat-form-field_personalizado" appearance="outline">
          <input matInput placeholder="{{'events.formLegendCommision' | translate}}" type="number" min="0" [(ngModel)]="nuevoItem.returnMoneyCommission" name="returnMoneyCommission" required commision #returnMoneyCommissionCtrl="ngModel">
          <mat-error *ngIf="commision_control.invalid">{{getErrorMessage_commision()}}</mat-error>
        </mat-form-field>
    </div>
    <!-- <div class="checkbox">
      <mat-checkbox [(ngModel)]="nuevoItem.isMoneyReturned" name="isMoneyReturned">{{ 'confEvent.return' | translate }}</mat-checkbox>
    </div> -->
    <div class="checkbox">
      <mat-checkbox [(ngModel)]="nuevoItem.hasRegistration" name="hasRegistration">{{ 'confEvent.register' | translate }}</mat-checkbox>
    </div>
    <div class="checkbox">
      <mat-checkbox [(ngModel)]="nuevoItem.hasAccessControl" name="hasAccessControl">{{"events.formHasAccessControl" | translate}}</mat-checkbox>
    </div>
    <div class="checkbox">
      <mat-checkbox [(ngModel)]="nuevoItem.isTicketScan" name="isTicketScan">{{ 'events.formTicketScan' | translate }}</mat-checkbox>
    </div>
    <div class="checkbox">
      <mat-checkbox [(ngModel)]="nuevoItem.hasNotifications" name="hasNotifications">{{ 'events.formHasNotifications' | translate }}</mat-checkbox>
    </div>
    <div class="checkbox">
      <mat-checkbox [(ngModel)]="nuevoItem.hasPreCharge" name="hasPreCharge">{{ 'confEvent.precharge' | translate }}</mat-checkbox>
    </div>
    <div class="checkbox">
      <mat-checkbox [(ngModel)]="nuevoItem.hasPurchases" name="hasPurchases">Cashless</mat-checkbox>
    </div>
    <div class="checkbox">
      <mat-checkbox [(ngModel)]="nuevoItem.hasExactPurchases" name="hasExactPurchases">{{ 'confEvent.exact' | translate }}</mat-checkbox>
    </div>
    <div class="checkbox">
      <mat-checkbox [(ngModel)]="nuevoItem.hasTpvCash" name="hasTpvCash">{{ 'confEvent.hasTpvCash' | translate }}</mat-checkbox>
    </div>
    <div class="checkbox">
      <mat-checkbox [(ngModel)]="nuevoItem.hasTpvCard" name="hasTpvCard">{{ 'confEvent.hasTpvCard' | translate }}</mat-checkbox>
    </div>
    <div class="checkbox">
      <mat-checkbox [(ngModel)]="nuevoItem.hasTpvWristband" name="hasTpvWristband">{{ 'confEvent.hasTpvWristband' | translate }}</mat-checkbox>
    </div>
    <div class="checkbox">
      <mat-checkbox [(ngModel)]="nuevoItem.hasOnlyVouchers" name="hasOnlyVouchers">{{ 'confEvent.onlyVoucher' | translate }}</mat-checkbox>
    </div>
    <div class="checkbox">
      <mat-checkbox [(ngModel)]="nuevoItem.hasVouchers" name="hasVouchers">{{"events.formHasVouchers" | translate}}</mat-checkbox>
    </div>
    <div class="checkbox">
      <mat-checkbox [(ngModel)]="nuevoItem.hasQR" name="hasQR">{{ 'confEvent.qr' | translate }}</mat-checkbox>
    </div>
    <div class="campo_formulario">
      <div class="item" fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="row" fxLayoutAlign="start center" style="width: 100%; margin: 0 20px 2em 0;">
            <span style="margin: 0 20px 0 0;" class="name primary-color">{{ 'create.color' | translate }}: </span>
            <button mat-button [matMenuTriggerFor]="menu" type="button" [ngStyle]="{'background-color': selectedColor !== '' ? selectedColor : 'white'}"
                    style="width: 36px; min-width: 36px; display: flex; justify-content: center; align-items: center;">
              <mat-icon svgIcon="pencil"
              [ngStyle]="{'color': selectedColor !== '' ? 'black' : 'black'}">
              </mat-icon>
            </button>
            <mat-menu #menu="matMenu" style="display: flex;">
              <div *ngFor="let theme of themeService.themes" style="width: 32px; height: 32px; margin: 3px ">
                <button mat-menu-item [style.background-color]="theme.primary" (click)="selectTheme(theme)">
                </button>
              </div>
            </mat-menu>
        </div>
      </div>
    </div>
    <div class="campo_formulario">
      <div style="padding-bottom: 100px">
        <div class="item" fxLayout="row" fxLayoutAlign="space-between center">
            <span class="name primary-color">{{ 'create.logo' | translate }}: </span>
            <div style="width: 100%; padding-left: 30px;">
                <input #file type="file" id="product-img" value="product-img" accept=".jpg,.png,.svg,.jpeg"
                    name="product-img" (change)="preview($event)">
            </div>
        </div>
        <div *ngIf="imgURL" fxLayout="row" fxLayoutAlign="center center" [ngStyle]="{'background-color': selectedColor}">
            <img [src]="imgURL" height="80" alt="">
        </div>
      </div>
    </div>


  </div>


    <div class="contenedor_botones_outside">
      <div class="contenedor_botones_inside" id = "contenedor_filtros_inside">
          <button id = "close_button" class="button cerrar" (click)="onClose()" >{{"events.formClose" | translate}}</button><br>
          <button id = "create_button"  class="button" (click)="onSubmit(f)" [disabled]="!f.valid" >{{"events.formCreate" | translate}}</button>
      </div>
    </div>

</form>



