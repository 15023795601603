import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

/**
 * LongPressDirective, manages the long pressing events.
 */
@Directive({selector: '[bracelitLongPress]'})
export class BracelitLongPressDirective {
  /**
   * Default longPress duration.
   * @type {number}
   */
  @Input() duration = 500;
  /**
   * longPress eventEmitter.
   * @type {EventEmitter<any>}
   */
  @Output() readonly longPress: EventEmitter<any> = new EventEmitter();
  /**
   * longPressing eventEmitter.
   * @type {EventEmitter<any>}
   */
  @Output() readonly longPressing: EventEmitter<any> = new EventEmitter();
  /**
   * longPressEnd eventEmitter.
   * @type {EventEmitter<any>}
   */
  @Output() readonly longPressEnd: EventEmitter<any> = new EventEmitter();

  /**
   * Pressing check
   * @type {boolean}
   */
  private pressing: boolean;
  /**
   * Long pressing check
   * @type {boolean}
   */
  private longPressingCheck: boolean;
  /**
   * timeOut
   * @type {any}
   */
  private timeout: any;

  /**
   * Loop event, emits longPressing.
   * @param $event
   */
  loop($event) {
    if (this.longPressing) {
      this.timeout = setTimeout(() => {
        this.longPressing.emit($event);
        this.loop($event);
      }, 50);
    }
  }

  /**
   * Touchstart event listener, emits longPress.
   * @param $event
   */
  @HostListener('touchstart', ['$event']) touchstart($event): void {
    this.pressing = true;
    this.longPressingCheck = false;
    this.timeout = setTimeout(() => {
      this.longPressingCheck = true;
      this.longPress.emit($event);
      this.loop($event);
    }, this.duration);
  }

  /**
   * Touchend event listener.
   */
  @HostListener('touchend') touchend(): void {
    this.touchEnd();
  }

  /**
   * Touchcancel event listener.
   */
  @HostListener('touchcancel') touchcancel(): void {
    this.touchEnd();
  }

  /**
   * TouchEnd, emits longPressEnd.
   */
  private touchEnd(): void {
    clearTimeout(this.timeout);
    this.longPressingCheck = false;
    this.pressing = false;
    this.longPressEnd.emit(true);
  }
}
