import { AfterContentChecked, ChangeDetectorRef, Component } from '@angular/core';

import { AuthenticationService, PwaService, ThemeService  } from '@shared/services';

import { environment } from '../environments/environment';
import { TranslateConfigService } from './shared/services/translate-config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterContentChecked {
  title = 'bracelit-admin';

  constructor(public themeService: ThemeService,
              public authService: AuthenticationService,
              private pwaService: PwaService,
              private translateConfigService: TranslateConfigService,
              public cdref: ChangeDetectorRef) {
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }
}
