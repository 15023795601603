import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { Product } from '@shared/models/product';

@Injectable()
export class WorkersService {
  private workers: any;
  private workerPush: any = {};
  workersChanged = new Subject<Product[]>();
  private roles: any[] = [];
  rolesChanged = new Subject<Product[]>();
  private worker: any;
  public workersId: any;
  public l: any;
  workersList = [];
  workerRole: any;

  constructor() {}

  setWorkers(workers) {
    this.workers = workers;
    this.workersChanged.next(this.workers.staffmembersInfo.slice());
    this.workerList(this.workers);

    return this.workers;
  }

  getWorkers() {
    //return this.workers.slice();
    return this.workers;
  }

  setRoles(roles) {
    this.roles = roles;
    this.rolesChanged.next(this.roles.slice());
    return this.roles;
  }

  getRoles() {
    return this.roles.slice();
  }

  addNewWorker(worker) {
    const staffmember: any = {};
    staffmember.name = worker.name;
    staffmember.surname = worker.surname;
    staffmember.id = worker.id;
    staffmember.password = worker.password;
    staffmember.roleId = worker.roleId.id;
    staffmember.userName = worker.username;

    for (const role of this.getRoles().slice()) {
      if (role.id === worker.roleId.id) {
        staffmember.role = role.name;
      }
    }

    this.workers.staffmembersInfo.push(staffmember);
    this.workersChanged.next(this.workers.staffmembersInfo.slice());
    this.workerList(this.workers);
  }

  addNewWorker2(worker) {
    const adminmember: any = {};
    //const staffuser: any = {};
    //adminmember.name = worker.name;
    //adminmember.surname = worker.surname;
    adminmember.name = worker.email;
    adminmember.id = worker.id;
    adminmember.password = worker.password;
    adminmember.roleId = worker.roleId.id;
    //adminmember.username = worker.username;
    // this.workerPush = {staffmember: staffmember, staffuser: staffuser, categories: worker.categories};
    this.workers.staffmembersInfo.push(adminmember);
    this.workersChanged.next(this.workers.staffmembersInfo.slice());
    this.workerList(this.workers);
  }

  editWorker(worker, workerUpdate) {
    let index = -1;
    const object = this.workers.staffmembersInfo.find(function(value, i) {
      if (value.id === worker.id) {
        index = i;
        return i;
      }
    });

    this.workers.staffmembersInfo[index].name = workerUpdate.name;
    this.workers.staffmembersInfo[index].surname = workerUpdate.surname;
    this.workers.staffmembersInfo[index].userName = workerUpdate.username;
    this.workers.staffmembersInfo[index].roleId = workerUpdate.roleId;

    for (const role of this.getRoles().slice()) {
      if (role.id === workerUpdate.roleId) {
        this.workers.staffmembersInfo[index].role = role.name;
      }
    }
    this.workersChanged.next(this.workers.staffmembersInfo.slice());
    this.workerList(this.workers);
  }

  deleteWorker(workerId) {
    let index = -1;
    const object = this.workers.staffmembersInfo.find(function(value, i) {
      if (value.id === workerId) {
        index = i;
        return i;
      }
    });

    this.workers.staffmembersInfo.splice(index, 1);
    this.workersChanged.next(this.workers.staffmembersInfo.slice());
    this.workerList(this.workers);
  }

  editSetWorker(worker) {
    this.worker = worker;

    return this.worker;
  }

  editGetWorker() {
    return this.worker;
  }

  workerList(workers) {
    this.workers = workers;
    this.workersList = [];
    this.roles = this.getRoles().slice();
    for (this.worker of this.workers.staffmembersInfo) {
      for (const role of this.roles) {
        if (role.id === this.worker.roleId) {
          this.workerRole = role.name;
        }
      }
      if (this.worker.profileImageUrl !== undefined) {
        this.workersList.push({name: this.worker.name, surname: this.worker.surname,
          username: this.worker.userName, profileImageUrl: this.worker.profileImageUrl,
          id: this.worker.id, role: this.workerRole, categories: ' ', staffUserId: ' ', totalWrist: ' '});
      } else {
        this.workersList.push({name: this.worker.name, surname: this.worker.surname,
          username: this.worker.userName, profileImageUrl: ' ',
          id: this.worker.id, role: this.workerRole, categories: ' ', staffUserId: ' ', totalWrist: ' '});
      }
    }

    return this.workersList;
  }
}
