import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MaterialModule } from '@shared/angular-material';

import { BracelitPreloaderComponent } from './bracelit-preloader.component';
import {FlexLayoutModule} from '@angular/flex-layout';

@NgModule({
  declarations: [
    BracelitPreloaderComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule
  ],
  exports: [
    BracelitPreloaderComponent
  ]
})
export class BracelitPreloaderModule { }
