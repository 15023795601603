import { Location } from '@angular/common';
import { Component, DoCheck, Input } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';

import { AuthenticationService } from '@shared/services/authentication.service';;

/**
 * BracelitToolbarComponent, an absolute positioned toolbar to print, go help, back or edit.
 */
@Component({
  selector: 'bracelit-toolbar',
  templateUrl: './bracelit-toolbar.component.html',
  styleUrls: ['./bracelit-toolbar.component.scss'],
})
export class BracelitToolbarComponent implements DoCheck {
  /**
   * The routerOutlet which contains the info.
   * @type {RouterOutlet}
   */
  @Input() routerOutlet: RouterOutlet;
  /**
   * Checks if edit button is available.
   * @type {boolean}
   */
  showEditButton = false;
  /**
   * Checks if help button is available.
   * @type {boolean}
   */
  showHelpButton = false;

  /**
   * Constructor.
   * @param location
   * @param router
   * @param authenticationService
   */
  constructor(private location: Location,
              private router: Router,
              private authenticationService: AuthenticationService) {
  }

  /**
   * DoCheck lifecycle.
   */
  ngDoCheck() {
    if (this.routerOutlet.activatedRouteData['editAbility']) {
      this.showEditButton = this.authenticationService.isSetAbility(this.routerOutlet.activatedRouteData['editAbility']);
    } else {
      this.showEditButton = false;
    }
    this.showHelpButton = !!this.routerOutlet.activatedRouteData['helpRoute'];
  }

  /**
   * Goes back.
   */
  onBack() {
    this.location.back();
    window.scroll(0, 0);
  }

  /**
   * Navigates to helpRoute.
   */
  onHelp() {
    this.router.navigate([this.routerOutlet.activatedRouteData['helpRoute']]);
  }

  /**
   * Navigates to edit route.
   */
  onEdit() {
    this.router.navigate([this.routerOutlet.activatedRoute.snapshot['_routerState']['url'] + '/editar']);
  }

  /**
   * Prints the window.
   */
  onPrint() {
    window.print();
  }
}
