import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class LoaderService {
  loaderOptions = {
    loadingState: false,
    loadingBackground: false,
    loadingBackgroundHeight: '100%',
    loadingBackgroundWidth: '100%',
    loaderDimensions: '150px',
    loaderText: '',
  }

  constructor() {
  }

  setLoader(loadingState, loadingBackground, loadingBackgroundWidth, loadingBackgroundHeight, loaderDimensions, loaderText){
    this.loaderOptions.loadingState = loadingState;
    this.loaderOptions.loadingBackground = loadingBackground;
    this.loaderOptions.loadingBackgroundWidth = loadingBackgroundWidth;
    this.loaderOptions.loadingBackgroundHeight = loadingBackgroundHeight;
    this.loaderOptions.loaderDimensions = loaderDimensions;
    this.loaderOptions.loaderText = loaderText;
  }

  getLoader(){
    return this.loaderOptions
  }


}
