import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import esJson from '../../../../assets/i18n/es.json';
import enJson from '../../../../assets/i18n/en.json';
import { DataStorageService } from '@app/shared/services/data-storage.service';
import { AuthenticationService } from '@app/shared/services';
import { FormBuilder, FormGroup, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatTooltip } from '@angular/material/tooltip';
import { DialogComponent } from '@app/workers/dialog/dialog.component';

@Component({
  selector: 'app-invitations-dialog',
  templateUrl: './invitations-dialog.component.html',
  styleUrls: ['./invitations-dialog.component.scss']
})
export class InvitationsDialogComponent {
  langJson: any;
  logoToUpload: FormGroup;
  fileToUpload: File | null = null;
  imageToUpload: FormGroup;
  fileLogoName: string = '';
  fileExcelName: string = '';
  fileTemplateName: string = '';
  typeExcelError: boolean = false;
  typeTemplateError: boolean = false;
  type: any;
  errorExcelMsg: string;
  errorTemplateMsg: string;
  myForm: UntypedFormGroup;
  public showTooltip1:boolean = false;
  public showTooltip2:boolean = false;
  @ViewChild('tooltip1') tooltip1: MatTooltip;
  @ViewChild('tooltip2') tooltip2: MatTooltip;
  
  constructor(public dialog: MatDialog,
    public dialogRef: MatDialogRef<InvitationsDialogComponent>,
    private dataStorageService: DataStorageService,
    private formBuilder: UntypedFormBuilder,
    public authService: AuthenticationService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public message: string) {
      this.langJson = (localStorage.getItem('lang') === 'es') ? esJson : enJson;
      this.logoToUpload = this.fb.group({
        logo: null
      });
      this.imageToUpload = this.fb.group({
        image: null
      });
      this.myForm = formBuilder.group({
        subject: '',
        bodytext: ''
      });
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

  handleFileLogoInput(files: any) {
    const file = files.item(0);
    this.fileLogoName = file.name;
    this.logoToUpload.patchValue({logo: file});
  }

  handleFileExcelInput(files: any) {
    this.fileToUpload = files.item(0);
    let type = this.fileToUpload.type;
    this.fileExcelName = this.fileToUpload.name;

    if (!type.includes('spreadsheetml')){
      this.typeExcelError = true;
      this.errorTemplateMsg = "Formato de archivo incorrecto. El archivo debe ser formato xlsx."
    } else {
      this.typeTemplateError = false;
    }
  }

  handleFileTemplateInput(files: any) {
    const file = files.item(0);
    let type = file.type;
    this.fileTemplateName = file.name;
    const image = new Image();
    const _URL = window.URL || window.webkitURL;
    const objectUrl = _URL.createObjectURL(file);
    image.src = objectUrl;
    image.onload = () => {
      const {
        height,
        width
      } = image;
      if (height !== 4711 || width !== 3313) {
        this.typeTemplateError = true;
        this.errorTemplateMsg = "Tamaño de imagen incorrecto. La imagen debe tener alto 4711px y ancho 3313px."
      } else {
        if (type !== 'image/jpeg'){
          this.typeTemplateError = true;
          this.errorTemplateMsg = "Formato de imagen incorrecto. La imagen debe ser formato jpg."
        } else {
          this.typeTemplateError = false;
        }
      }
    };

    if(!this.typeTemplateError) {
      this.imageToUpload.patchValue({image: file});
    }
  }

  sendInvitations() {
    this.authService.setLoader(true);
    const formData = new FormData();
    formData.append("file", this.logoToUpload.controls['logo'].value);
    const formData2 = new FormData();
    formData2.append("file", this.imageToUpload.controls['image'].value);
    if(this.fileLogoName && this.fileExcelName && this.fileTemplateName && !this.typeExcelError && !this.typeTemplateError) {
      this.dataStorageService.sendInvitationAbanca(this.fileToUpload, formData, formData2, this.myForm.value['subject'], this.myForm.value['bodytext']);
      this.dialogRef.close();
    }
  }

  toggle(id) {
    if (id === 1) {
      if (!this.showTooltip1) {
        this.tooltip1.show();

        const dialogRef2 = this.dialog.open(DialogComponent, {
          width: '350px',
          data:{
            message: this.langJson.selectFile,
            upload: true,
            type: 'invitations'
          }
        });
        dialogRef2.afterClosed().subscribe(result => {
          if (result) {
            this.dataStorageService.importProducts(result).subscribe(data => {
              //this.importResult = data;
            }, error => {
              console.log(error);
              if (error.status === 0){
              //this.snackbarService.openSnackBar(this.langJson.products.errorImport);
              }
            });
            //this.snackbarService.openSnackBar(this.langJson.products.successImport);
          }
        });
      } else {
        this.tooltip1.hide();
      }
      this.showTooltip1 = !this.showTooltip1;
    } else if (id === 2) {
      if (!this.showTooltip2) {
        this.tooltip2.show();

        const dialogRef2 = this.dialog.open(DialogComponent, {
          width: '350px',
          data:{
            message: this.langJson.selectFile,
            upload: true,
            type: 'photo'
          }
        });
        dialogRef2.afterClosed().subscribe(result => {
          if (result) {
            this.dataStorageService.importProducts(result).subscribe(data => {
              //this.importResult = data;
            }, error => {
              console.log(error);
              if (error.status === 0){
              //this.snackbarService.openSnackBar(this.langJson.products.errorImport);
              }
            });
            //this.snackbarService.openSnackBar(this.langJson.products.successImport);
          }
        });
      } else {
        this.tooltip2.hide();
      }
      this.showTooltip2 = !this.showTooltip2;
    }
  }
}
